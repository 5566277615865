import { Badge } from "@cpchem/covalence-ui";
import { FilterListItem } from "@models";
import React from "react";

export interface FilterBadgeProps {
    className?: string;
    testId?: string;
    title: string;
    appliedFilter: FilterListItem;
    onDismiss: (id: string, title: string) => void;
}

export function FilterBadge({
    className,
    testId,
    title,
    appliedFilter,
    onDismiss
}: FilterBadgeProps): JSX.Element {
    const textFilterTestId = testId ? `${testId}-text-filter-badge` : undefined;

    return (
        <div
            className={className}
            data-testid={`${testId}-text-${appliedFilter.id}`}
            key={appliedFilter.id}
        >
            <Badge
                isDismissible
                onDismissRequested={() => onDismiss(appliedFilter.id, title)}
                title={title}
                text={appliedFilter.name}
                size="medium"
                color="blue"
                variant="subtle"
                testId={`${textFilterTestId}-${appliedFilter.id}`}
            />
        </div>
    );
}

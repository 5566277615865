import React from "react";

export function PowerBiView(): JSX.Element {
    return (
        <div className="tag-management-table-view">
            <div className="tag-management-table">
                <div className="my-requests">
                    {
                        <div className="header" data-testid="">
                            <p className="title" data-testid="">
                                Power BI
                            </p>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

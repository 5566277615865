import { MyRequestsFilterContainer } from "@components/filters/my-requests-filter-container";
import { Card } from "@cpchem/covalence-ui";
import {
    MyRequestsFilters,
    MyRequestsFiltersList,
    MyRequestsSelectedFilters
} from "@models/filters";
import { TestIds } from "@pages/tag-management-page/test-ids";
import {
    SITE_FILTER,
    SOURCE_SYSTEM_FILTER
} from "@stores/filters/filter-names";
import { useTagManagement } from "@stores/tag-management-context";
import { resetFilter } from "@utilities/filters/reset-filter";
import { resetFilterList } from "@utilities/filters/reset-filter-list";
import { getSelectedFiltersFromList } from "@utilities/filters/set-selected-filters-from-list-on-load";
import React, { useState } from "react";
import { HermesSearchBox } from "../../../../../app/components/hermes-search-box";

interface MyRequestsSearchFilterViewProps {
    placeHolderText?: string;
    toolTipText?: string;
}
export const MyRequestsSearchFilterView = ({
    placeHolderText,
    toolTipText
}: MyRequestsSearchFilterViewProps) => {
    const { state, dispatch } = useTagManagement();
    const { hasActiveFilter } = state;

    const [selectedFilters, setSelectedFilters] = useState<MyRequestsFilters>({
        sites: getSelectedFiltersFromList(state.siteFilters),
        sourceSystems: getSelectedFiltersFromList(state.sourceSystemFilters)
    });

    function setCurrentPageState(page: number) {
        dispatch({ type: "SET_CURRENT_PAGE", payload: page });
    }

    const applyFiltersDismiss = (id: string, filterName: string) => {
        const filterActions = {
            [SITE_FILTER.TITLE]: () => {
                setSelectedFilters(() => ({
                    ...selectedFilters,
                    sites: selectedFilters.sites.filter(
                        (filterId) => filterId !== id
                    )
                }));
                dispatch({
                    type: "SET_SITE_FILTER",
                    payload: {
                        siteFilters: resetFilter(state.siteFilters, id)
                    }
                });
            },
            [SOURCE_SYSTEM_FILTER.TITLE]: () => {
                setSelectedFilters(() => ({
                    ...selectedFilters,
                    sourceSystems: selectedFilters.sourceSystems.filter(
                        (filterId) => filterId !== id
                    )
                }));
                dispatch({
                    type: "SET_SOURCE_SYSTEM_FILTER",
                    payload: {
                        sourceSystemFilters: resetFilter(
                            state.sourceSystemFilters,
                            id
                        )
                    }
                });
            }
        };

        const action = filterActions[filterName];
        if (action) {
            action();
        } else console.error("Unknown filterName", filterName);

        setCurrentPageState(1);
    };

    const applyFilters = (
        filtersList: MyRequestsFiltersList,
        selectedFilters: MyRequestsSelectedFilters
    ) => {
        setSelectedFilters(() => ({
            sites: [...selectedFilters.selectedSites],
            sourceSystems: [...selectedFilters.selectedSourceSystems]
        }));

        dispatch({
            type: "SET_MY_REQUESTS_FILTERS",
            payload: {
                siteFilters: filtersList.siteFilterList,
                sourceSystemFilters: filtersList.sourceSystemFilterList
            }
        });

        setCurrentPageState(1);
    };

    const clearFilters = () => {
        setSelectedFilters(() => ({
            sites: [...[]],
            sourceSystems: [...[]]
        }));

        setCurrentPageState(1);
        dispatch({
            type: "SET_MY_REQUESTS_FILTERS",
            payload: {
                siteFilters: resetFilterList(state.siteFilters),
                sourceSystemFilters: resetFilterList(state.sourceSystemFilters)
            }
        });
    };

    return (
        <div className="search-filter-content" data-testid="">
            <Card className="search-filter-card" testId="">
                <div className="search-filter-bar" id="search-filter-bar">
                    <div className="hermes-search-box">
                        <HermesSearchBox
                            placeHolder={placeHolderText}
                            toolTip={toolTipText}
                        />
                    </div>
                    <MyRequestsFilterContainer
                        applyFilters={applyFilters}
                        applyFiltersDismiss={applyFiltersDismiss}
                        clearFilters={clearFilters}
                        hasActiveFilter={hasActiveFilter}
                        testId={TestIds.TagManagement.FilterContainer}
                    />
                </div>
            </Card>
        </div>
    );
};

import { DataGridRow } from "@cpchem/covalence-ui";
import { IngestedTag } from "@services/hermes/model";
import React from "react";

export function ResultRowFull(row: DataGridRow): JSX.Element {
    const tagInfo = row.values as IngestedTag;
    return <tr key={`${row.id}`}>{GetBaseColumnsRow(tagInfo)}</tr>;
}

export function FilteredEmptyResults() {
    return (
        <div className="no-results">
            <div className="no-results-content">
                <div className="no-results-title">
                    No Tags match the current <b>Search Term</b> and/or{" "}
                    <b>Filters</b>.
                </div>
                <div className="no-results-subtitle">
                    Expecting to see Tags? Make sure the <b>Search Term</b> &{" "}
                    <b>Filters</b> are applied correctly.
                </div>
            </div>
        </div>
    );
}

function GetBaseColumnsRow(row: IngestedTag): JSX.Element {
    return (
        <>
            <td className="items-cell left">{row.site}</td>
            <td className="items-cell left">{row.source}</td>
            <td className="items-cell left"> {row.tagName}</td>
            <td className="items-cell left">{row.engUnits}</td>
            <td className="items-cell left">{row.sourceFrequency}</td>
            <td className="items-cell left">{row.aggregationType}</td>
            <td className="items-cell left">{row.pullFrequency}</td>
            <td className="items-cell left">{row.frequencyMode}</td>
            <td className="items-cell left">{row.enabled.toString()}</td>
        </>
    );
}

import { FilterContainer } from "@components/filters";
import { Card } from "@cpchem/covalence-ui";
import {
    FiltersList,
    SelectedFilters,
    TagManagementFilters
} from "@models/filters";
import { TestIds } from "@pages/tag-management-page/test-ids";
import {
    AGGREGATION_TYPE_FILTER,
    SITE_FILTER,
    SOURCE_FREQUENCY_FILTER,
    SOURCE_SYSTEM_FILTER,
    STATUS_FILTER
} from "@stores/filters/filter-names";
import { useTagManagement } from "@stores/tag-management-context";
import { resetFilter } from "@utilities/filters/reset-filter";
import { resetFilterList } from "@utilities/filters/reset-filter-list";
import { getSelectedFiltersFromList } from "@utilities/filters/set-selected-filters-from-list-on-load";
import React, { useState } from "react";
import { HermesSearchBox } from "../../../../app/components/hermes-search-box";

interface SearchFilterViewProps {
    placeHolderText?: string;
    toolTipText?: string;
}
export const SearchFilterView = ({
    placeHolderText,
    toolTipText
}: SearchFilterViewProps) => {
    const { state, dispatch } = useTagManagement();
    const { hasActiveFilter } = state;

    const [selectedFilters, setSelectedFilters] =
        useState<TagManagementFilters>({
            sites: getSelectedFiltersFromList(state.siteFilters),
            sourceSystems: getSelectedFiltersFromList(
                state.sourceSystemFilters
            ),
            sourceFrequencies: getSelectedFiltersFromList(
                state.sourceFrequencyFilters
            ),
            aggregationTypes: getSelectedFiltersFromList(
                state.aggregationTypeFilters
            ),
            statuses: getSelectedFiltersFromList(state.statusFilters)
        });

    function setCurrentPageState(page: number) {
        dispatch({ type: "SET_CURRENT_PAGE", payload: page });
    }

    const applyFiltersDismiss = (id: string, filterName: string) => {
        const filterActions = {
            [SITE_FILTER.TITLE]: () => {
                setSelectedFilters(() => ({
                    ...selectedFilters,
                    sites: selectedFilters.sites.filter(
                        (filterId) => filterId !== id
                    )
                }));
                dispatch({
                    type: "SET_SITE_FILTER",
                    payload: {
                        siteFilters: resetFilter(state.siteFilters, id)
                    }
                });
            },
            [SOURCE_SYSTEM_FILTER.TITLE]: () => {
                setSelectedFilters(() => ({
                    ...selectedFilters,
                    sourceSystems: selectedFilters.sourceSystems.filter(
                        (filterId) => filterId !== id
                    )
                }));
                dispatch({
                    type: "SET_SOURCE_SYSTEM_FILTER",
                    payload: {
                        sourceSystemFilters: resetFilter(
                            state.sourceSystemFilters,
                            id
                        )
                    }
                });
            },
            [SOURCE_FREQUENCY_FILTER.TITLE]: () => {
                setSelectedFilters(() => ({
                    ...selectedFilters,
                    sourceFrequencies: selectedFilters.sourceFrequencies.filter(
                        (filterId) => filterId !== id
                    )
                }));
                dispatch({
                    type: "SET_SOURCE_FREQUENCY_FILTER",
                    payload: {
                        sourceFrequencyFilters: resetFilter(
                            state.sourceFrequencyFilters,
                            id
                        )
                    }
                });
            },
            [AGGREGATION_TYPE_FILTER.TITLE]: () => {
                setSelectedFilters(() => ({
                    ...selectedFilters,
                    aggregationTypes: selectedFilters.aggregationTypes.filter(
                        (filterId) => filterId !== id
                    )
                }));
                dispatch({
                    type: "SET_AGGREGATION_TYPE_FILTER",
                    payload: {
                        aggregationTypeFilters: resetFilter(
                            state.aggregationTypeFilters,
                            id
                        )
                    }
                });
            },
            [STATUS_FILTER.TITLE]: () => {
                setSelectedFilters(() => ({
                    ...selectedFilters,
                    statuses: selectedFilters.statuses.filter(
                        (filterId) => filterId !== id
                    )
                }));
                dispatch({
                    type: "SET_STATUS_FILTER",
                    payload: {
                        statusFilters: resetFilter(state.statusFilters, id)
                    }
                });
            }
        };

        const action = filterActions[filterName];
        if (action) {
            action();
        } else console.error("Unknown filterName", filterName);

        setCurrentPageState(1);
    };

    const applyFilters = (
        filtersList: FiltersList,
        selectedFilters: SelectedFilters
    ) => {
        setSelectedFilters(() => ({
            sites: [...selectedFilters.selectedSites],
            sourceSystems: [...selectedFilters.selectedSourceSystems],
            sourceFrequencies: [...selectedFilters.selectedSourceFrequencies],
            aggregationTypes: [...selectedFilters.selectedAggregationTypes],
            statuses: [...selectedFilters.selectedStatuses]
        }));

        dispatch({
            type: "SET_ALL_FILTERS",
            payload: {
                siteFilters: filtersList.siteFilterList,
                sourceSystemFilters: filtersList.sourceSystemFilterList,
                sourceFrequencyFilters: filtersList.sourceFrequencyFilterList,
                aggregationTypeFilters: filtersList.aggregationTypeFilterList,
                statusFilters: filtersList.statusFilterList
            }
        });

        setCurrentPageState(1);
    };

    const clearFilters = () => {
        setSelectedFilters(() => ({
            sites: [...[]],
            sourceSystems: [...[]],
            sourceFrequencies: [...[]],
            aggregationTypes: [...[]],
            statuses: [...[]]
        }));

        setCurrentPageState(1);
        dispatch({
            type: "SET_ALL_FILTERS",
            payload: {
                siteFilters: resetFilterList(state.siteFilters),
                sourceSystemFilters: resetFilterList(state.sourceSystemFilters),
                sourceFrequencyFilters: resetFilterList(
                    state.sourceFrequencyFilters
                ),
                aggregationTypeFilters: resetFilterList(
                    state.aggregationTypeFilters
                ),
                statusFilters: resetFilterList(state.statusFilters)
            }
        });
    };

    return (
        <div className="search-filter-content" data-testid="">
            <Card className="search-filter-card" testId="">
                <div className="search-filter-bar" id="search-filter-bar">
                    <div className="hermes-search-box">
                        <HermesSearchBox
                            placeHolder={placeHolderText}
                            toolTip={toolTipText}
                        />
                    </div>
                    <FilterContainer
                        applyFilters={applyFilters}
                        applyFiltersDismiss={applyFiltersDismiss}
                        clearFilters={clearFilters}
                        hasActiveFilter={hasActiveFilter}
                        testId={TestIds.TagManagement.FilterContainer}
                    />
                </div>
            </Card>
        </div>
    );
};

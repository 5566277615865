import { AccountInformation } from "@cpchem/azure-auth";

export function getInitialsFromName(
    currentAccount: AccountInformation | null
): string {
    if (currentAccount !== null) {
        if (currentAccount.name && currentAccount.name.length > 0) {
            const { name } = currentAccount;
            const userLetters = name.split(" ");
            if (name !== undefined && name !== null && name.length > 0) {
                if (userLetters.length > 1)
                    return `${userLetters[1].substring(
                        0,
                        1
                    )}${userLetters[0].substring(0, 1)}`;
                return "";
            }
            return "";
        }
    }

    return "";
}

import { RequestTitleViewTestIds } from "@pages/tag-management-page/test-ids";
import { useTagManagement } from "@stores/tag-management-context";
import React from "react";
import HermesAccordion from "../../../../app/components/hermes-accordion";
import "./styles.scss";
interface RequestTitleProps {
    title?: string;
    status: string;
    lastUpdated?: string;
}
export const RequestTitleView = ({
    title,
    status,
    lastUpdated
}: RequestTitleProps) => {
    const { state } = useTagManagement();
    const { tagRequest } = state;
    return (
        <div data-testid={RequestTitleViewTestIds.HermesAccordion}>
            <HermesAccordion
                title={title}
                status={status}
                lastUpdated={lastUpdated}
            >
                <p>{tagRequest.requestReason}</p>
            </HermesAccordion>
        </div>
    );
};

import React from "react";

export function AppIcon(): JSX.Element {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="29"
            viewBox="0 0 32 29"
            fill="none"
        >
            <path
                d="M11.7418 19.856C11.7418 23.5773 9.26695 26.7115 5.87084 27.714C5.13923 27.9308 4.35344 28.0482 3.54957 28.0482H-8.33542e-05C3.39602 27.0457 5.87084 23.9115 5.87084 20.1902L5.87084 8.80966C5.87084 5.0884 8.34566 1.95423 11.7418 0.95166V19.856Z"
                fill="url(#paint0_linear_389_30687)"
            />
            <ellipse
                cx="15.9227"
                cy="14.4226"
                rx="3.09677"
                ry="3.09676"
                fill="url(#paint1_linear_389_30687)"
            />
            <path
                d="M20.2581 9.14384C20.2581 5.42258 22.7329 2.28841 26.129 1.28584C26.8607 1.06906 27.6465 0.95165 28.4503 0.95165H32C28.6039 1.95422 26.129 5.08839 26.129 8.80965L26.129 20.1902C26.129 23.9115 23.6542 27.0456 20.2581 28.0482L20.2581 9.14384Z"
                fill="url(#paint2_linear_389_30687)"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_389_30687"
                    x1="3.81298"
                    y1="28.0394"
                    x2="13.278"
                    y2="2.03453"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#000F9F" />
                    <stop offset="1" stopColor="#0D53FF" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_389_30687"
                    x1="17.4711"
                    y1="11.3258"
                    x2="14.6066"
                    y2="17.0548"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#78BE20" />
                    <stop offset="0.992432" stopColor="#00AEC7" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear_389_30687"
                    x1="28.1869"
                    y1="0.960429"
                    x2="18.7219"
                    y2="26.9654"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#000F9F" />
                    <stop offset="1" stopColor="#0D53FF" />
                </linearGradient>
            </defs>
        </svg>
    );
}

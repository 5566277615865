interface ServiceLocator {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    instances: { [key: string]: any };
    set<T>(serviceId: string, instance: T): void;
    get<T>(serviceId: string): T;
}

export default {
    instances: [],
    set<T>(serviceId: string, instance: T): void {
        this.instances[serviceId] = instance;
    },
    get<T>(serviceId: string): T {
        return this.instances[serviceId];
    }
} as ServiceLocator;

export function TimeDifferenceFromNow(dateTimeString: string): string {
    const inputDate = new Date(dateTimeString);
    const currentDate = new Date();
    const timeDIfferenceInSeconds =
        Math.floor(currentDate.getTime() - inputDate.getTime()) / 1000;
    if (timeDIfferenceInSeconds < 60) {
        if (timeDIfferenceInSeconds < 1) return "just now";
        if (timeDIfferenceInSeconds < 2) return "1 second ago";
        return `${Math.round(timeDIfferenceInSeconds)} seconds ago`;
    }
    const timeDifferenceInMinutes = Math.floor(timeDIfferenceInSeconds / 60);
    if (timeDifferenceInMinutes < 60) {
        if (timeDifferenceInMinutes < 2) return "1 minute ago";
        return `${timeDifferenceInMinutes} minutes ago`;
    }
    const timeDifferenceInHours = Math.floor(timeDifferenceInMinutes / 60);
    if (timeDifferenceInHours < 24) {
        if (timeDifferenceInHours < 2) return "1 hour ago";
        return `${timeDifferenceInHours} hours ago`;
    }
    const timeDifferenceInDays = Math.floor(timeDifferenceInHours / 24);
    if (timeDifferenceInDays < 30) {
        if (timeDifferenceInDays < 2) return "1 day ago";
        return `${timeDifferenceInDays} days ago`;
    }
    const timeDifferenceInMonths = Math.floor(timeDifferenceInDays / 30);
    if (timeDifferenceInMonths < 12) {
        if (timeDifferenceInMonths < 2) return "1 month ago";
        return `${timeDifferenceInMonths} months ago`;
    }
    const timeDifferenceInYears = Math.floor(timeDifferenceInMonths / 12);
    if (timeDifferenceInYears < 2) return "1 year ago";
    return `${timeDifferenceInYears} years ago`;
}

import {
    DataGridColumnDefinition,
    FilteringDataGridColumnDefinition
} from "@cpchem/covalence-ui";
export const baseColumns: (
    | DataGridColumnDefinition
    | FilteringDataGridColumnDefinition
)[] = [
    {
        Header: "Request Id",
        accessor: "id",
        alignment: "left",
        sortType: "number",
        id: "id",
        filter: "includesValue"
    },
    {
        Header: "Request Name",
        accessor: "requestName",
        alignment: "left",
        sortType: "string",
        id: "requestName",
        filter: "includesValue"
    },
    {
        Header: "Site",
        accessor: "site",
        alignment: "left",
        sortType: "string",
        id: "site",
        filter: "includesValue"
    },
    {
        Header: "Source",
        accessor: "source",
        alignment: "left",
        sortType: "string",
        id: "source",
        filter: "includesValue"
    },
    {
        Header: "Requested By",
        accessor: "requestedBy",
        alignment: "left",
        sortType: "string",
        id: "requestedBy",
        filter: "includesValue"
    },
    {
        Header: "Requested Date",
        accessor: "requestedDate",
        alignment: "left",
        sortType: "string",
        id: "requestedDate",
        filter: "includesValue"
    }
];

import config from "@config";
import { AuthManager } from "@cpchem/azure-auth";

export async function getOtherGraphUserPhoto(
    userIdentifier: string
): Promise<string | null | undefined> {
    const authMgr = AuthManager.getInstance();
    const token = await authMgr.getApiToken(config.graph.photo.scopes);
    const headers = new Headers();
    const bearer = `Bearer ${token}`;
    headers.append("Authorization", bearer);
    const options = {
        method: "GET",
        headers: headers,
        encoding: null
    };

    // Replace `me` with the userIdentifier (UPN or email) of the user whose photo you want to retrieve
    const endpoint = `${config.graph.photo.endpoint}/users/${userIdentifier}/photo/$value`;

    const responsePhotoRequest = await fetch(endpoint, options);

    if (responsePhotoRequest.ok) {
        const photoBlob = await responsePhotoRequest.blob();
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                resolve(reader.result as string);
            };
            reader.onerror = () => reject(null);
            reader.readAsDataURL(photoBlob);
        });
    }
    return null;
}

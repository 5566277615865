import { LoadingDisplay } from "@components/loading";
import { HistoryLogViewTestIds } from "@pages/tag-management-page/test-ids";
import { Activity } from "@services/hermes/model";
import { TimeDifferenceFromNow } from "@utilities/time-difference-from-now";
import React from "react";
import { LogCommentView } from "../log-comment-view";
import "./styles.scss";

interface HistoryLogViewProps {
    changeLogHistory: Activity[];
    isTagActivityUpdateLoading?: boolean;
    isRequestComment?: boolean;
}

export const HistoryLogView = ({
    changeLogHistory,
    isTagActivityUpdateLoading,
    isRequestComment = true
}: HistoryLogViewProps) => {
    return (
        <>
            <LoadingDisplay
                className="tag-comment-loading-display"
                showLoading={isTagActivityUpdateLoading ?? false}
            />
            <div
                className="history-log-list"
                data-testid={HistoryLogViewTestIds.HistoryLogList}
            >
                {changeLogHistory.map((logHistory) => {
                    return (
                        <div className="history-log" key={logHistory.id}>
                            <div className="history-log-avatar-comment">
                                <LogCommentView
                                    activity={logHistory}
                                    isRequestComment={isRequestComment}
                                />
                            </div>
                            <p
                                className="history-log-modified-last"
                                data-testid={
                                    HistoryLogViewTestIds.HistoryLogModifiedLast
                                }
                            >
                                {TimeDifferenceFromNow(
                                    logHistory.commentedDate
                                )}
                            </p>
                        </div>
                    );
                })}
            </div>
        </>
    );
};

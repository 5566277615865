import {
    DataGridColumnDefinition,
    FilteringDataGridColumnDefinition
} from "@cpchem/covalence-ui";

export const baseColumns: (
    | DataGridColumnDefinition
    | FilteringDataGridColumnDefinition
)[] = [
    {
        Header: "Id",
        accessor: "id",
        alignment: "left",
        sortType: "string",
        id: "id",
        filter: "includesValue"
    },
    {
        Header: "Site",
        accessor: "site",
        alignment: "left",
        sortType: "string",
        id: "site",
        filter: "includesValue"
    },
    {
        Header: "Source System",
        accessor: "source",
        alignment: "left",
        sortType: "string",
        id: "source",
        filter: "includesValue"
    },
    {
        Header: "Tag Name",
        accessor: "tagName",
        alignment: "left",
        sortType: "string",
        id: "tagName",
        filter: "includesValue"
    },
    {
        Header: "Eng Units",
        accessor: "engUnits",
        alignment: "left",
        sortType: "string",
        id: "engUnits",
        filter: "includesValue"
    },
    {
        Header: "Source Frequency",
        accessor: "sourceFrequency",
        alignment: "left",
        sortType: "string",
        id: "sourceFrequency",
        filter: "includesValue"
    },
    {
        Header: "Aggregation Type",
        accessor: "aggregationType",
        alignment: "left",
        sortType: "string",
        id: "aggregationType",
        filter: "includesValue"
    },
    {
        Header: "Pull Frequency",
        accessor: "pullFrequency",
        alignment: "left",
        sortType: "string",
        id: "pullFrequency",
        filter: "includesValue"
    },
    {
        Header: "",
        accessor: "edit",
        alignment: "center"
    },
    {
        Header: "",
        accessor: "action",
        alignment: "center"
    }
];

import { ProvidedConfig, getProvidedConfig } from "./utilities/provided-config";

export interface Config extends ProvidedConfig {
    env: string;
    connected: boolean;
}

// eslint-disable-next-line no-undef
const env = process.env.NODE_ENV;
const envConnected = import.meta.env.VITE_CONNECTED
    ? import.meta.env.VITE_CONNECTED === "true"
    : false;
const connected = env === "production" || envConnected;
const providedConfig: ProvidedConfig = getProvidedConfig(env);

const combinedConfig: Config = {
    ...providedConfig,
    env: env || "unknown",
    connected
};

export const buildConfig = {
    viteBuildDate: import.meta.env.VITE_BUILD_DATE
};

export default combinedConfig;

import {
    AlertBanner,
    AlertOptions,
    DataGrid,
    DataGridProps,
    useAlertBanner
} from "@cpchem/covalence-ui";
import FileUpload from "@pages/tag-management-page/components/create-new-tags-request-view/create-new-request-view/file-upload-view";
import { useTagManagementContext } from "@pages/tag-management-page/context";
import { NewTagsRequestTestIds } from "@pages/tag-management-page/test-ids";
import { HermesService, HermesServiceKey } from "@services";
import { Tag } from "@services/hermes/model";
import { useTagManagement } from "@stores/tag-management-context";
import { parse } from "papaparse";
import React from "react";
import { useService } from "../../../../service-provider";
import { baseColumns } from "./base-columns";
import { TagRow } from "./renderer/row";

export function NewRequestTagsDisplay() {
    const { state, dispatch } = useTagManagement();
    const { onSetLoading } = useTagManagementContext();
    const hermesService = useService<HermesService>(HermesServiceKey);
    const { selectedSite, selectedSource, tagRequest } = state;
    const { createAlertBanner, dismissAlertBanner } = useAlertBanner();
    let requestTags: Tag[] = [];
    if (tagRequest.items) {
        requestTags = tagRequest.items;
    }

    async function handleFileRead(data: string) {
        dismissAlertBanner();
        onSetLoading(true);
        let parsedTags: Tag[] = [];
        try {
            parse(data, {
                header: true,
                skipEmptyLines: true,
                complete: (results: { data: Tag[] }) => {
                    parsedTags = results.data;

                    if (parsedTags.length > 250) {
                        throw new Error(
                            "Uploaded file contains more than 250 tags. You can upload a maximum of 250 tags per request."
                        );
                    }

                    // Check if every site has a non empty site, source and tagName
                    const allFieldsValid = parsedTags.every(
                        (tag) =>
                            tag.site &&
                            tag.site.trim() !== "" &&
                            tag.source &&
                            tag.source.trim() !== "" &&
                            tag.tagName &&
                            tag.tagName.trim() !== ""
                    );

                    if (!allFieldsValid) {
                        throw new Error(
                            "All tags must have non-empty Site, Source & TagName."
                        );
                    }

                    // check if all site values are same
                    const allSitesSame = parsedTags.every(
                        (tag, _, array) => tag.site === array[0].site
                    );

                    if (!allSitesSame) {
                        throw new Error(
                            "All Site values in the file must be same."
                        );
                    }

                    // check if all source values are same
                    const allSourcesSame = parsedTags.every(
                        (tag, _, array) => tag.source === array[0].source
                    );

                    if (!allSourcesSame) {
                        throw new Error(
                            "All Source values in the file must be same."
                        );
                    }

                    // check if all tag names are unique
                    const tagNames = new Set(
                        parsedTags.map((tag) => tag.tagName?.trim())
                    );
                    if (tagNames.size !== parsedTags.length) {
                        throw new Error(
                            "Tag Names in the file must be unique."
                        );
                    }

                    // check if site and source combination from file matches selected options on page
                    if (
                        selectedSite !== parsedTags[0].site ||
                        selectedSource !== parsedTags[0].source
                    ) {
                        throw new Error(
                            `Site and/or Source from tags in file upload is different from selected options. Please correct the file and re-upload`
                        );
                    }
                },
                error: (error: { message: unknown }) => {
                    throw new Error(`${error.message}`);
                }
            });
            const tagNames = parsedTags.map((tag) => tag.tagName) as string[];
            await ValidateAndFetchLookupData(
                selectedSite,
                selectedSource,
                tagNames
            );
        } catch (parseError) {
            const error = parseError as Error;
            console.error("Error processing file: ", error);
            const alertOptions: AlertOptions = {
                message: error.message,
                severity: "danger",
                dismissable: true
            };
            createAlertBanner(alertOptions);
        } finally {
            onSetLoading(false);
        }
    }

    async function ValidateAndFetchLookupData(
        site: string | undefined,
        source: string | undefined,
        tags: string[]
    ) {
        try {
            const tagLookupResults =
                await hermesService.ValidateAndGetTagsMetadata(
                    site,
                    source,
                    tags
                );

            if (tagLookupResults.code !== 200) {
                let errorMessage = "";
                if (tagLookupResults.code === 400) {
                    const strInvalidTags = tagLookupResults.data.join(", ");
                    errorMessage = `${state.uploadedFileName} file contains invalid tag names: ${strInvalidTags}`;
                } else
                    errorMessage = tagLookupResults.message
                        ? tagLookupResults.message
                        : "Unknown error occurred, please contact EHFIL Dev Support Group";

                throw new Error(errorMessage);
            }
            dispatch({
                type: "SET_TAG_REQUEST_DATA",
                payload: {
                    tagRequest: {
                        ...tagRequest,
                        site: selectedSite,
                        source: selectedSource,
                        items: tagLookupResults.data
                    }
                }
            });
            dismissAlertBanner();
            if (state.uploadedFileName) {
                const alertOptions: AlertOptions = {
                    message: `${state.uploadedFileName} has been successfully uploaded.`,
                    severity: "success",
                    dismissable: true
                };
                createAlertBanner(alertOptions);
            }
        } catch (tagProcessingError) {
            const error = tagProcessingError as Error;
            console.error("Error fetching lookup data: ", error);
            const alertOptions: AlertOptions = {
                message: error.message,
                severity: "danger",
                dismissable: true
            };
            createAlertBanner(alertOptions);
        }
    }

    async function handleFileUpload(file: File) {
        try {
            if (!file.name.endsWith(".csv") && file.type !== "text/csv") {
                throw new Error("Uploaded file type must be CSV");
            }
            setUploadedFileName(file.name);
            const reader = new FileReader();
            reader.readAsText(file);
            reader.onload = (e) => {
                const text = e.target?.result;
                if (typeof text === "string") {
                    handleFileRead(text);
                }
            };
            reader.onerror = (e) => {
                console.error("Error reading file: ", e.target?.error);
            };
            reader.onabort = (e) => {
                console.error("File read aborted: ", e);
            };
        } catch (fileParseError) {
            const error = fileParseError as Error;
            console.error("Error fetching lookup data: ", error);
            const alertOptions: AlertOptions = {
                message: error.message,
                severity: "danger",
                dismissable: true
            };
            createAlertBanner(alertOptions);
        }
    }

    function setUploadedFileName(fileName: string) {
        dispatch({
            type: "SET_UPLOADED_FILE_NAME",
            payload: {
                uploadedFileName: fileName
            }
        });
    }

    const handleDeleteTagRow = (tagName: string | undefined) => {
        const updatedTags = tagRequest.items.filter(
            (tag) => tag.tagName?.toLowerCase() !== tagName?.toLowerCase()
        );

        dispatch({
            type: "SET_TAG_REQUEST_DATA",
            payload: {
                tagRequest: { ...tagRequest, items: updatedTags }
            }
        });

        dismissAlertBanner();
        if (requestTags.length > 0) {
            const alertOptions: AlertOptions = {
                message: `Tag: ${tagName} removed`,
                severity: "success",
                dismissable: true
            };
            createAlertBanner(alertOptions);
        }
    };

    const gridProps: DataGridProps = {
        data: requestTags,
        columns: baseColumns,
        useStickyHeaders: true,
        rowRenderer: (row) => (
            <TagRow key={row.id} row={row} deleteTagRow={handleDeleteTagRow} />
        ),
        emptyResultsRenderer: () => handleEmptyResults()
    };

    function handleEmptyResults() {
        if (selectedSite && selectedSource) {
            return SiteAndSourceSelectedRenderer();
        }
        return SiteAndSourceNotSelectedRenderer();
    }

    function SiteAndSourceSelectedRenderer() {
        return <FileUpload onFileUpload={handleFileUpload} />;
    }

    function SiteAndSourceNotSelectedRenderer() {
        return (
            <div className="add-upload-tags-grid-no-results">
                <div className="add-upload-tags-grid-no-results-content">
                    <div className="add-upload-tags-grid-no-results-title ">
                        Please select a <b>SITE</b> and <b>SOURCE</b> to add or
                        upload tags
                    </div>
                </div>
            </div>
        );
    }

    return (
        <>
            <div className="add-upload-tags-grid">
                <DataGrid
                    key={requestTags.length}
                    {...gridProps}
                    testId={NewTagsRequestTestIds.AddUploadTagsGrid}
                />
            </div>
            <AlertBanner />
        </>
    );
}

export const routePath = {
    LANDING: "/",
    TAG_MANAGEMENT: "/tag-management",
    MY_REQUESTS: "/tag-management/my-requests",
    REQUEST_PREVIEW: "/tag-management/my-requests/request-preview/:requestId",
    NEW_REQUEST: "/tag-management/new-request",
    MY_APPROVALS: "/tag-management/my-approvals",
    APPROVAL_PREVIEW:
        "/tag-management/my-approvals/approval-preview/:requestId",
    POWER_BI: "/tag-management/powerbi"
};

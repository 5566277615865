import { IngestedTagsDisplay } from "@components/display/tag-displays/ingested-tags/ingested-tags-display";
import { Card, DisplayAmount } from "@cpchem/covalence-ui";
import { TestIds } from "@pages/tag-management-page/test-ids";
import { getNextSortValues, sortValue } from "@utilities/api/helper";
import React from "react";
import { useTagManagement } from "../../../../../stores/tag-management-context";
import { SearchFilterView } from "../../search-filter-view";

export const IngestedTagsTableView = () => {
    const { state, dispatch } = useTagManagement();
    const {
        ingestedTags,
        totalIngestedTagCount,
        rowsToDisplay,
        currentPage,
        sortValue
    } = state;

    const onPageChange = (newPage: number) => {
        setCurrentPageState(newPage);
    };
    const onRowsToDisplayChange = (newRowsToDisplay: string) => {
        setRowsToDisplayState(newRowsToDisplay);
    };
    function setCurrentPageState(page: number) {
        dispatch({ type: "SET_CURRENT_PAGE", payload: page });
    }
    function setRowsToDisplayState(rowsToDisplay: string) {
        dispatch({
            type: "SET_ROWS_TO_DISPLAY",
            payload: Number(rowsToDisplay)
        });
    }

    const onSelectSortValue = (newSort: sortValue, currentSort: sortValue) => {
        const newSortValue = getNextSortValues(currentSort, newSort);
        dispatch({
            type: "SET_OrderBy_TO_DISPLAY",
            payload: newSortValue
        });
    };
    return (
        <div
            className="ingested-tags-content"
            data-testid={TestIds.IngestedTags.IngestedTagsContent}
        >
            <SearchFilterView
                placeHolderText="Search Tags (use at least 3 characters)"
                toolTipText="Search for Tag Names"
            />
            <Card>
                <IngestedTagsDisplay
                    ingestedTags={ingestedTags}
                    totalIngestedTagCount={totalIngestedTagCount}
                    rowsToDisplay={rowsToDisplay as DisplayAmount}
                    currentPage={currentPage}
                    onDisplayPageChange={onRowsToDisplayChange}
                    goToPage={onPageChange}
                    onSelectSortValue={onSelectSortValue}
                    currentSortValue={sortValue}
                />
            </Card>
        </div>
    );
};

import { LoadingDisplay } from "@components/loading";
import { useAuth } from "@cpchem/azure-auth-react";
import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import { HermesDrawer } from "../../app/components/hermes-drawer";
import "./styles.scss";
import { TestIds } from "./test-ids";

export function TagManagementPage(): JSX.Element {
    let cn = "tag-management-page";
    const [isLoading, setIsLoading] = useState(true);
    const { currentAccount } = useAuth({});
    const isApprover = currentAccount?.roles?.includes("Hermes.Approver");
    if (isApprover) cn += " approver";

    function onSetLoading(isLoading: boolean) {
        setIsLoading(isLoading);
    }

    return (
        <>
            <div
                className={cn.toLocaleLowerCase()}
                data-testid={TestIds.TagManagement}
            >
                <div className="sidebar">
                    <HermesDrawer />
                </div>
                <LoadingDisplay
                    className="loading-display"
                    showLoading={isLoading}
                >
                    <div className="outlet">
                        <Outlet
                            context={{
                                isLoading,
                                onSetLoading,
                                isApprover,
                                currentAccount
                            }}
                        />
                    </div>
                </LoadingDisplay>
            </div>
        </>
    );
}

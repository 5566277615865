import { TextField, useAlertBanner } from "@cpchem/covalence-ui";
import { NewTagsRequestTestIds } from "@pages/tag-management-page/test-ids";
import { useTagManagement } from "@stores/tag-management-context";
import React, { useState } from "react";
export const NewRequestDetailsView = () => {
    const { state, dispatch } = useTagManagement();
    const { tagRequest } = state;
    const [requestNameError, setRequestNameError] = useState("");
    const [businessJustificationError, setBusinessJustificationError] =
        useState("");
    const [requestName, setRequestName] = useState("");
    const [businessReason, setBusinessReason] = useState("");
    const { dismissAlertBanner } = useAlertBanner();
    const onRequestNameChanged = (newValue: string) => {
        if (newValue.length > 0) {
            dismissAlertBanner();
            setRequestNameError("");
        }
        setRequestName(newValue);
        tagRequest.requestName = newValue;
        setTagRequestData();
    };

    const onBusinessReasonChanged = (newValue: string) => {
        if (newValue.length > 0) {
            dismissAlertBanner();
            setBusinessJustificationError("");
        }
        setBusinessReason(newValue);
        tagRequest.requestReason = newValue;
        setTagRequestData();
    };

    function setTagRequestData() {
        dispatch({
            type: "SET_TAG_REQUEST_DATA",
            payload: {
                tagRequest: { ...tagRequest }
            }
        });
    }

    return (
        <div
            className="new-request-details-view"
            data-testid={NewTagsRequestTestIds.NewRequestDetailsViewHeader}
        >
            <div className="request-name-text">
                <TextField
                    placeholder="Enter Request Name"
                    onChange={onRequestNameChanged}
                    label="Request Name"
                    value={requestName}
                    testId={NewTagsRequestTestIds.RequestNameText}
                    errorMessage={requestNameError}
                />
            </div>
            <div className="business-justification-text">
                <TextField
                    placeholder="Enter your Business Reason"
                    onChange={onBusinessReasonChanged}
                    label="Business Justification"
                    value={businessReason}
                    isMultiline
                    testId={NewTagsRequestTestIds.BusinessJustificationText}
                    errorMessage={businessJustificationError}
                />
            </div>
        </div>
    );
};

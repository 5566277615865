import { FilterListItem } from "../../models/filters";

export const getSelectedFiltersFromList = (filterItems: FilterListItem[]) => {
    const filterList: string[] = [];
    filterItems.map((item) => {
        if (item.checked) {
            filterList.push(item.name);
        }
    });

    return filterList;
};

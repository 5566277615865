import { RequestTagReviewDisplay } from "@components/display/request-displays/request-tag-review/request-tag-review-display";
import {
    Button,
    Card,
    Divider,
    Modal,
    ModalContent,
    TextField,
    useToast
} from "@cpchem/covalence-ui";
import { useTagManagementContext } from "@pages/tag-management-page/context";
import { RequestPreviewTestIds } from "@pages/tag-management-page/test-ids";
import { HermesService, HermesServiceKey } from "@services";
import {
    InitialFilterState,
    LogHistoryAPIResponse,
    RequestTagAPIResponse
} from "@services/hermes/interface";
import { Activity, Log, TagRequest } from "@services/hermes/model";
import {
    FilterType,
    PULL_FREQUENCY_FILTER
} from "@stores/filters/filter-names";
import { useTagManagement } from "@stores/tag-management-context";
import { capitalizeFirstLetter } from "@utilities/capitalize-first-letter";
import { buildSelectOptionsList } from "@utilities/filters/build-select-options-list";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useService } from "../../../../../service-provider";
import { HistoryLogView } from "../../history-log-view";
import { RequestHistoryView } from "../../request-history-view";
import { RequestTitleView } from "../../request-title-view";
import "./styles.scss";

export function RequestPreview(): JSX.Element {
    const { requestId } = useParams();
    const { state, dispatch } = useTagManagement();
    const { tagRequest, tagLogHistory } = state;
    const hermesService = useService<HermesService>(HermesServiceKey);
    const { isLoading, onSetLoading } = useTagManagementContext();
    const [isRequestCommentLoading, setRequestCommentLoading] = useState(false);
    const [isTagUpdateLoading, setTagUpdateLoading] = useState(false);
    const [isTagActivityUpdateLoading, setTagActivityUpdateLoading] =
        useState(false);
    const [successModalVisible, setSuccessModalVisible] = useState(false);
    const { createToast } = useToast();
    const [requestAction, setRequestAction] = useState("Updated");
    const requestCompleteOrClosed =
        tagRequest.requestStatus?.toLowerCase() === "complete" ||
        tagRequest.requestStatus?.toLowerCase() === "closed";
    const [tagIdForActivity, setTagIdForActivity] = useState<number>(0);
    const [tagNameForActivity, setTagNameForActivity] = useState("");
    const [tagActivityModalVisible, setTagActivityModalVisible] =
        useState(false);
    const [tagNote, setTagNote] = useState("");

    async function getRequestDetails() {
        try {
            onSetLoading(true);
            const response = await hermesService.GetRequest(Number(requestId));
            if (response.data) {
                const tagActionsResponse =
                    response.data as RequestTagAPIResponse;
                const results = tagActionsResponse.data as TagRequest;
                setTagRequestDataState(results);
            }
        } catch (error) {
            console.error(error);
        } finally {
            onSetLoading(false);
        }
    }

    function onSuccessModalClosed() {
        setSuccessModalVisible(false);
    }

    const handleUpdateTagRow = async (
        id: string,
        tagName: string | undefined,
        pullFrequency: string | undefined,
        comments: Log | null | undefined
    ) => {
        try {
            setTagUpdateLoading(true);
            const tagReviewed = tagRequest.items.find(
                (tag) => tag.tagName === tagName
            );

            if (tagReviewed) {
                const updatedTag = {
                    ...tagReviewed,
                    pullFrequency: pullFrequency,
                    comments: comments
                };

                await hermesService
                    .UpdateTag({
                        ...updatedTag,
                        ...{ requestId: tagRequest.id }
                    })
                    .then(async (updateTagResponse) => {
                        if (updateTagResponse.code === 200) {
                            await hermesService
                                .GetRequest(Number(requestId))
                                .then((getRequestDetailsResponse) => {
                                    if (getRequestDetailsResponse.data) {
                                        const tagActionsResponse =
                                            getRequestDetailsResponse.data as RequestTagAPIResponse;
                                        const results =
                                            tagActionsResponse.data as TagRequest;
                                        setTagRequestDataState(results);

                                        createToast(
                                            `Tag ${tagName} updated successfully!`,
                                            {
                                                id: `tag-${tagName}-updated`,
                                                severity: "info"
                                            }
                                        );
                                    }
                                });
                        } else {
                            const errorMessage = updateTagResponse.message
                                ? updateTagResponse.message
                                : "Unknown error occurred, please contact EHFIL Dev Support Group";
                            throw new Error(errorMessage);
                        }
                    });
            } else {
                throw new Error(
                    "Unknown error occurred, please contact EHFIL Dev Support Group"
                );
            }
        } catch (tagProcessingError) {
            const error = tagProcessingError as Error;
            createToast(
                `Tag ${tagName} update unsuccessful. ${error.message}`,
                {
                    id: `tag-${tagName}-not-updated`,
                    severity: "danger",
                    autoDismiss: false,
                    autoDismissDelay: 10000
                }
            );
        } finally {
            setTagUpdateLoading(false);
        }
    };

    const handleTagActivityModalOpen = async (
        tagId: number,
        tagName: string | undefined
    ) => {
        try {
            setTagUpdateLoading(true);
            if (tagName) {
                await hermesService
                    .GetTagLogHistory(tagRequest.id, tagId)
                    .then((tagLogResponse) => {
                        if (tagLogResponse.data) {
                            const tagActionsResponse =
                                tagLogResponse.data as LogHistoryAPIResponse;
                            const results: Activity[] =
                                tagActionsResponse.data.map((item) => {
                                    let parsedLog;
                                    try {
                                        parsedLog = item.log
                                            ? JSON.parse(item.log)
                                            : "";
                                    } catch (e) {
                                        // if the log is already an object or not a valid JSON,
                                        // then just use the log as is
                                        parsedLog = item.log || "";
                                    }
                                    return {
                                        ...item,
                                        log: parsedLog
                                    };
                                });

                            const sortedResults = results.sort(
                                (a, b) =>
                                    new Date(b.commentedDate).getTime() -
                                    new Date(a.commentedDate).getTime()
                            );

                            setTagLogHistoryDataState(sortedResults);
                            setTagIdForActivity(tagId);
                            setTagNameForActivity(tagName);
                            setTagActivityModalVisible(true);
                        }
                    });
            }
        } catch (tagLogProcessingError) {
            const error = tagLogProcessingError as Error;
            createToast(
                `Could not retrieve ${tagName} activity. ${error.message}`,
                {
                    id: `tag-${tagName}-activity-error`,
                    severity: "danger",
                    autoDismiss: false,
                    autoDismissDelay: 10000
                }
            );
        } finally {
            setTagUpdateLoading(false);
        }
    };

    function setTagLogHistoryDataState(tagLogHistory: Activity[]) {
        dispatch({
            type: "SET_TAG_LOG_HISTORY_DATA",
            payload: {
                tagLogHistory: tagLogHistory
            }
        });
    }

    function setTagRequestDataState(updatedTagRequest: TagRequest) {
        dispatch({
            type: "SET_TAG_REQUEST_DATA",
            payload: {
                tagRequest: { ...updatedTagRequest }
            }
        });
    }

    async function getLookupItems(filterType: FilterType) {
        try {
            const filterResponse = await hermesService.GetLookupData(
                filterType.ID
            );

            if (filterResponse.data) {
                const data = filterResponse.data as string[];
                setSelectOptionsState(filterType.TITLE, data);
            }
        } catch (error) {
            setSelectOptionsState(
                filterType.TITLE,
                InitialFilterState.filterItems
            );
        }
    }

    async function setSelectOptionsState(filterName: string, data: string[]) {
        const selectOptionActions = {
            [PULL_FREQUENCY_FILTER.TITLE]: () => {
                dispatch({
                    type: "SET_PULL_FREQUENCY_SELECT_OPTIONS",
                    payload: {
                        pullFrequencySelectOptions: buildSelectOptionsList(
                            PULL_FREQUENCY_FILTER.TITLE,
                            data
                        )
                    }
                });
            }
        };

        const action = selectOptionActions[filterName];
        if (action) action();
        else console.error("Unknown filterName", filterName);
    }

    async function getAllInitialLookupItems() {
        getLookupItems(PULL_FREQUENCY_FILTER);
    }

    function setRequestLogHistoryDataState(requestLogHistory: Activity[]) {
        dispatch({
            type: "SET_REQUEST_LOG_HISTORY_DATA",
            payload: {
                requestLogHistory: requestLogHistory
            }
        });
    }

    async function GetRequestLogHistoryDetails() {
        try {
            const response = await hermesService.GetRequestLogHistory(
                Number(requestId)
            );
            if (response.data) {
                const requestActionsResponse =
                    response.data as LogHistoryAPIResponse;

                const results: Activity[] = requestActionsResponse.data.map(
                    (item) => ({
                        ...item,
                        log: item.log ? JSON.parse(item.log) : ""
                    })
                );
                setRequestLogHistoryDataState(results);
            }
        } catch (error) {
            console.error(error);
        }
    }

    const successModalText =
        requestAction === "Approved" ? "Approval" : "Close";

    async function UpdateRequestByAction(
        action: string,
        requestComment: string | null
    ) {
        try {
            setRequestCommentLoading(true);
            await hermesService
                .UpdateRequestByAction(tagRequest.id, action, requestComment)
                .then(async (updateRequestResponse) => {
                    if (updateRequestResponse.code === 200) {
                        if (action.toLowerCase() === "closed") {
                            const updatedTagRequest = {
                                ...tagRequest,
                                requestStatus: action
                            };
                            setTagRequestDataState(updatedTagRequest);
                        }

                        await hermesService
                            .GetRequestLogHistory(Number(requestId))
                            .then((requestLogResponse) => {
                                const requestActionsResponse =
                                    requestLogResponse.data as LogHistoryAPIResponse;

                                const results: Activity[] =
                                    requestActionsResponse.data.map((item) => ({
                                        ...item,
                                        log: JSON.parse(item.log)
                                    }));
                                setRequestLogHistoryDataState(results);
                            });

                        showModalOrToast(action);
                    } else {
                        const errorMessage = updateRequestResponse.message
                            ? updateRequestResponse.message
                            : "Unknown error occurred, please contact EHFIL Dev Support Group";
                        throw new Error(errorMessage);
                    }
                });
        } catch (requestProcessing) {
            const error = requestProcessing as Error;
            createErrorToastByAction(error, action);
        } finally {
            setRequestCommentLoading(false);
        }
    }

    function showModalOrToast(action: string) {
        if (action === "closed") {
            setRequestAction("Closed");
            setSuccessModalVisible(true);
        } else {
            setRequestAction("Updated");
            createSuccessToastByAction(action);
        }
    }

    function createErrorToastByAction(error: Error, action: string) {
        if (action === "approved") {
            createToast(`Request not approved. ${error.message}`, {
                id: `request-approval-action-unsuccessful`,
                severity: "danger",
                autoDismiss: false,
                autoDismissDelay: 10000
            });
        } else if (action === "closed") {
            createToast(`Request not closed. ${error.message}`, {
                id: `request-closed-action-unsuccessful`,
                severity: "danger",
                autoDismiss: false,
                autoDismissDelay: 10000
            });
        } else {
            createToast(`Request not updated. ${error.message}`, {
                id: `request-update-action-unsuccessful`,
                severity: "danger",
                autoDismiss: false,
                autoDismissDelay: 10000
            });
        }
    }

    function createSuccessToastByAction(action: string) {
        if (action === "commented") {
            createToast(`New comment posted`, {
                id: `request-update-action-successful`,
                severity: "info",
                autoDismiss: false,
                autoDismissDelay: 10000
            });
        } else {
            createToast(`Request updated successfully!`, {
                id: `request-update-action-successful`,
                severity: "success",
                autoDismiss: false,
                autoDismissDelay: 10000
            });
        }
    }

    const addNoteAction = (tagId: number, action: string, note: string) => {
        if (note === "") {
            displayNoteRequiredToast();
        } else {
            addTagNote(tagId, action, note);
            setTagNote("");
        }
    };

    async function addTagNote(tagId: number, action: string, note: string) {
        try {
            setTagActivityUpdateLoading(true);
            await hermesService
                .UpdateTagByAction(tagRequest.id, tagId, action, note)
                .then(async (updateTagResponse) => {
                    if (updateTagResponse.code === 200) {
                        await hermesService
                            .GetRequest(Number(requestId))
                            .then(async (getRequestDetailsResponse) => {
                                let results: TagRequest;
                                if (getRequestDetailsResponse.data) {
                                    const tagActionsResponse =
                                        getRequestDetailsResponse.data as RequestTagAPIResponse;
                                    results =
                                        tagActionsResponse.data as TagRequest;
                                    setTagRequestDataState(results);
                                }

                                await hermesService
                                    .GetTagLogHistory(tagRequest.id, tagId)
                                    .then((tagLogResponse) => {
                                        if (tagLogResponse.data) {
                                            const tagActionsResponse =
                                                tagLogResponse.data as LogHistoryAPIResponse;
                                            const results: Activity[] =
                                                tagActionsResponse.data.map(
                                                    (item) => {
                                                        let parsedLog;
                                                        try {
                                                            parsedLog = item.log
                                                                ? JSON.parse(
                                                                      item.log
                                                                  )
                                                                : "";
                                                        } catch (e) {
                                                            // if the log is already an object or not a valid JSON,
                                                            // then just use the log as is
                                                            parsedLog =
                                                                item.log || "";
                                                        }
                                                        return {
                                                            ...item,
                                                            log: parsedLog
                                                        };
                                                    }
                                                );

                                            const sortedResults = results.sort(
                                                (a, b) =>
                                                    new Date(
                                                        b.commentedDate
                                                    ).getTime() -
                                                    new Date(
                                                        a.commentedDate
                                                    ).getTime()
                                            );

                                            setTagLogHistoryDataState(
                                                sortedResults
                                            );
                                            createSuccessToastByAction(action);
                                        }
                                    });
                            });
                    } else {
                        const errorMessage = updateTagResponse.message
                            ? updateTagResponse.message
                            : "Unknown error occurred, please contact EHFIL Dev Support Group";
                        throw new Error(errorMessage);
                    }
                });
        } catch (tagProcessing) {
            const error = tagProcessing as Error;
            createErrorToastByAction(error, action);
        } finally {
            setTagActivityUpdateLoading(false);
        }
    }

    function displayNoteRequiredToast() {
        createToast(`Please enter a Note!`, {
            id: `enter-note-toast`,
            severity: "danger",
            autoDismiss: false,
            autoDismissDelay: 10000
        });
    }

    const onTagNoteEntered = (newValue: string) => {
        setTagNote(newValue);
    };

    function onTagActivityModalClosed() {
        setTagActivityModalVisible(false);
    }

    useEffect(() => {
        getRequestDetails();
        GetRequestLogHistoryDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requestId, dispatch]);

    useEffect(() => {
        getAllInitialLookupItems();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="request-preview">
            <Card testId={RequestPreviewTestIds.RequestTitleViewCard}>
                <RequestTitleView
                    title={tagRequest.requestName}
                    status={capitalizeFirstLetter(tagRequest.requestStatus)}
                    lastUpdated={tagRequest.modifiedLast ?? ""}
                />
            </Card>
            {!isLoading && (
                <Card
                    testId={RequestPreviewTestIds.RequestTagsReviewDisplayCard}
                >
                    <RequestTagReviewDisplay
                        updateTagRow={handleUpdateTagRow}
                        tagActivityModalOpen={handleTagActivityModalOpen}
                        isTagUpdateLoading={isTagUpdateLoading}
                    />
                </Card>
            )}
            <Card testId={RequestPreviewTestIds.RequestHistoryView}>
                <RequestHistoryView
                    closeRequest={(action: string, requestComment: string) =>
                        UpdateRequestByAction(action, requestComment)
                    }
                    saveComment={(action: string, requestComment: string) =>
                        UpdateRequestByAction(action, requestComment)
                    }
                    hideRequestComment={requestCompleteOrClosed}
                    isRequestCommentLoading={isRequestCommentLoading}
                />
            </Card>
            <Modal
                onRequestClose={onSuccessModalClosed}
                title="Success"
                isOpen={successModalVisible}
                className="success-modal"
                testId={RequestPreviewTestIds.SuccessModal}
            >
                <ModalContent
                    testId={RequestPreviewTestIds.SuccessModalContent}
                >
                    <div className="success-modal-question">
                        Request {successModalText} Successful!
                    </div>
                    <div className="success-modal-text">
                        {tagRequest.requestName} request was successfully{" "}
                        {requestAction}!
                    </div>
                </ModalContent>
            </Modal>
            <Modal
                onRequestClose={onTagActivityModalClosed}
                title={`${tagNameForActivity} Activity`}
                isOpen={tagActivityModalVisible}
                className="tag-activity-modal"
                testId={RequestPreviewTestIds.TagActivityModal}
            >
                <ModalContent
                    testId={RequestPreviewTestIds.TagActivityModalContent}
                >
                    <div className="tag-activity-modal-note">
                        <TextField
                            placeholder="Enter Note Here..."
                            onChange={onTagNoteEntered}
                            label="Add a Note..."
                            value={tagNote}
                            isMultiline
                            isResizable
                            testId={RequestPreviewTestIds.EnterTagNote}
                        />
                        <div className="add-note-panel-button">
                            <Button
                                variant="solid"
                                size="medium"
                                text="Add Note"
                                className="add-note"
                                color="primary"
                                onClick={() =>
                                    addNoteAction(
                                        tagIdForActivity,
                                        "commented",
                                        tagNote
                                    )
                                }
                                testId={RequestPreviewTestIds.AddNote}
                            />
                        </div>
                    </div>
                    <Divider className="tag-activity-divider" />
                    <div
                        className="tag-activity-title"
                        data-testid={RequestPreviewTestIds.HistoryLogTitle}
                    >
                        Tag Activity
                    </div>
                    <div className="tag-activity-modal-history">
                        <HistoryLogView
                            changeLogHistory={tagLogHistory}
                            isTagActivityUpdateLoading={
                                isTagActivityUpdateLoading
                            }
                            isRequestComment={false}
                        />
                    </div>
                </ModalContent>
            </Modal>
        </div>
    );
}

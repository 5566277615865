import { ColumnDetailList } from "@components/display/base-header-display/types";

export const IngestTagColumnList: ColumnDetailList = {
    site: { sortBy: "site", textAlign: "left" },
    source: { sortBy: "source", textAlign: "left" },
    tagName: { sortBy: "tagName", textAlign: "left" },
    engUnits: { sortBy: "engUnits", textAlign: "left" },
    sourceFrequency: { sortBy: "sourceFrequency", textAlign: "left" },
    aggregationType: { sortBy: "aggregationType", textAlign: "left" },
    pullFrequency: { sortBy: "pullFrequency", textAlign: "left" },
    frequencyMode: { sortBy: "frequencyMode", textAlign: "left" },
    enabled: { sortBy: "enabled", textAlign: "left" }
};

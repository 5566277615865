export type FilterType = {
    ID: string;
    TITLE: string;
};

export const SITE_FILTER: FilterType = {
    ID: "site",
    TITLE: "Site"
};
export const SOURCE_SYSTEM_FILTER: FilterType = {
    ID: "source",
    TITLE: "Source System"
};
export const SOURCE_FREQUENCY_FILTER: FilterType = {
    ID: "sourcefrequency",
    TITLE: "Source Frequency"
};
export const AGGREGATION_TYPE_FILTER: FilterType = {
    ID: "aggregationtype",
    TITLE: "Aggregation Type"
};
export const STATUS_FILTER: FilterType = {
    ID: "enabled",
    TITLE: "Status"
};
export const TAG_NAME_FILTER: FilterType = {
    ID: "tagName",
    TITLE: "Tag Name"
};

export const PULL_FREQUENCY_FILTER: FilterType = {
    ID: "PullFrequency",
    TITLE: "Pull Frequency"
};

export const FREQUENCY_MODE_FILTER: FilterType = {
    ID: "FrequencyMode",
    TITLE: "Frequency Mode"
};

import { AriaSortType } from "@cpchem/covalence-ui";
interface SortToAria {
    [key: string]: AriaSortType;
}

interface SortToDirection {
    [key: string]: string;
}

export enum DataGridColumnSortState {
    ASC = "ascending",
    DESC = "descending",
    WAIT = "waiting to sort",
    DISABLED = "disabled"
}

export const AriaForTypes: SortToAria = {
    [DataGridColumnSortState.ASC]: "ascending",
    [DataGridColumnSortState.DESC]: "descending",
    [DataGridColumnSortState.WAIT]: "none"
};

export const DirectionForTypes: SortToDirection = {
    [DataGridColumnSortState.ASC]: "ASC",
    [DataGridColumnSortState.DESC]: "DESC",
    [DataGridColumnSortState.WAIT]: ""
};

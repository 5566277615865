import {
    DataGridColumnSortState,
    DirectionForTypes
} from "@components/display/base-header-display/constants";
import {
    GetIngestedTagsAPIRequest,
    GetMyApprovalsAPIRequest,
    GetMyRequestsAPIRequest,
    SearchIngestedTagsAPIRequest,
    SearchMyApprovalsAPIRequest,
    SearchMyRequestsAPIRequest
} from "@services/hermes/interface";
import {
    AGGREGATION_TYPE_FILTER,
    SITE_FILTER,
    SOURCE_FREQUENCY_FILTER,
    SOURCE_SYSTEM_FILTER,
    STATUS_FILTER
} from "@stores/filters/filter-names";
import {
    JsonItem,
    buildFilterListQuery
} from "@utilities/api/build-filter-list-query";

export type sortValueType = "string";

export type sortSequenceValues = {
    fromAsc: DataGridColumnSortState;
    fromDesc: DataGridColumnSortState;
    fromNone: DataGridColumnSortState;
};

export type sortValue = {
    column: string;
    sort: DataGridColumnSortState;
    type: sortValueType;
};
interface SortTypes {
    [sortType: string]: sortSequenceValues;
}
const sortValuesSequenceByType: SortTypes = {
    string: {
        fromAsc: DataGridColumnSortState.DESC,
        fromDesc: DataGridColumnSortState.WAIT,
        fromNone: DataGridColumnSortState.ASC
    }
};

export function getSortString(
    column: string,
    sort: DataGridColumnSortState
): string {
    return sort !== DataGridColumnSortState.WAIT
        ? `&$orderby=${column} ${DirectionForTypes[sort]}`
        : "";
}

export function getNextSortValues(
    currentSortValues: sortValue,
    selectedSortColumn: sortValue
): sortValue {
    const { column, sort } = currentSortValues;
    const isSameValue =
        column.toLowerCase() === selectedSortColumn.column.toLowerCase();
    const nextSortDirection = getNextSortDirectionByDataType(
        selectedSortColumn.type,
        isSameValue ? sort : DataGridColumnSortState.WAIT
    );
    return {
        column: selectedSortColumn.column,
        sort: nextSortDirection,
        type: selectedSortColumn.type
    };
}

export function getNextSortDirectionByDataType(
    type: sortValueType,
    currentSortDirection: DataGridColumnSortState
): DataGridColumnSortState {
    const typeSortSequence = sortValuesSequenceByType[type];
    if (currentSortDirection === DataGridColumnSortState.ASC) {
        return typeSortSequence.fromAsc;
    }
    if (currentSortDirection === DataGridColumnSortState.DESC) {
        return typeSortSequence.fromDesc;
    }
    return typeSortSequence.fromNone;
}

export function buildGetIngestedTagsRequestURL(
    baseURL: string,
    ingestedTagsRequest: GetIngestedTagsAPIRequest
): string {
    const startString = `?start=${ingestedTagsRequest.start}`;
    const endString = `&end=${ingestedTagsRequest.end}`;
    const pageSizeString = `&pageSize=${ingestedTagsRequest.pageSize}`;
    const orderByString = `&orderBy=${ingestedTagsRequest.orderBy}`;
    const sortString = `&desc=${ingestedTagsRequest.desc}`;
    const filtersQueryString = getFiltersQueryString(ingestedTagsRequest);
    const filtersString = `&filters=${filtersQueryString}`;
    const url = `${baseURL}${startString}${endString}${pageSizeString}${orderByString}${sortString}${filtersString}`;
    return url;
}

export function buildSearchIngestedTagsRequestURL(
    baseURL: string,
    searchRequest: SearchIngestedTagsAPIRequest
): string {
    const { fieldName, value } = searchRequest;
    const encodedValue = encodeURIComponent(value);
    const startString = `?start=${searchRequest.start}`;
    const endString = `&end=${searchRequest.end}`;
    const pageSizeString = `&pageSize=${searchRequest.pageSize}`;
    const orderByString = `&orderBy=${searchRequest.orderBy}`;
    const sortString = `&desc=${searchRequest.desc}`;
    const filtersQueryString = getFiltersQueryString(searchRequest);
    const filtersString = `&filters=${filtersQueryString}`;
    const url = `${baseURL}/${fieldName}/${encodedValue}${startString}${endString}${pageSizeString}${orderByString}${sortString}${filtersString}`;
    return url;
}

export function getFiltersQueryString(
    ingestedTagsAPIRequest: GetIngestedTagsAPIRequest
): string {
    const filterArray: JsonItem[] = [];
    if (ingestedTagsAPIRequest.siteFilters.length > 0) {
        const siteFilterItem = buildFilterListQuery(
            SITE_FILTER.ID,
            ingestedTagsAPIRequest.siteFilters
        );
        filterArray.push(siteFilterItem);
    }

    if (ingestedTagsAPIRequest.sourceSystemFilters.length > 0) {
        const sourceSystemFilterItem = buildFilterListQuery(
            SOURCE_SYSTEM_FILTER.ID,
            ingestedTagsAPIRequest.sourceSystemFilters
        );
        filterArray.push(sourceSystemFilterItem);
    }

    if (ingestedTagsAPIRequest.sourceFrequencyFilters.length > 0) {
        const sourceFrequencyFiltersItem = buildFilterListQuery(
            SOURCE_FREQUENCY_FILTER.ID,
            ingestedTagsAPIRequest.sourceFrequencyFilters
        );
        filterArray.push(sourceFrequencyFiltersItem);
    }

    if (ingestedTagsAPIRequest.aggregationTypeFilters.length > 0) {
        const aggregationTypeFiltersItem = buildFilterListQuery(
            AGGREGATION_TYPE_FILTER.ID,
            ingestedTagsAPIRequest.aggregationTypeFilters
        );
        filterArray.push(aggregationTypeFiltersItem);
    }

    if (ingestedTagsAPIRequest.statusFilters.length > 0) {
        const statusFiltersItem = buildFilterListQuery(
            STATUS_FILTER.ID,
            ingestedTagsAPIRequest.statusFilters
        );
        filterArray.push(statusFiltersItem);
    }

    return filterArray.length > 0
        ? encodeURIComponent(JSON.stringify(filterArray))
        : `[]`;
}

export function buildGetMyRequestsRequestURL(
    baseURL: string,
    myRequestsRequest: GetMyRequestsAPIRequest
): string {
    const startString = `?start=${myRequestsRequest.start}`;
    const endString = `&end=${myRequestsRequest.end}`;
    const pageSizeString = `&pageSize=${myRequestsRequest.pageSize}`;
    const orderByString = `&orderBy=${myRequestsRequest.orderBy}`;
    const sortString = `&desc=${myRequestsRequest.desc}`;
    const filtersQueryString = getFiltersQueryStringForMyRequests(
        myRequestsRequest.siteFilters,
        myRequestsRequest.sourceSystemFilters
    );
    const filtersString = `&filters=${filtersQueryString}`;
    const statusString = `&status=${myRequestsRequest.myRequestsStatus}`;
    const url = `${baseURL}${startString}${endString}${pageSizeString}${orderByString}${sortString}${filtersString}${statusString}`;
    return url;
}

export function buildGetMyApprovalsRequestURL(
    baseURL: string,
    myApprovalsRequest: GetMyApprovalsAPIRequest
): string {
    const startString = `?start=${myApprovalsRequest.start}`;
    const endString = `&end=${myApprovalsRequest.end}`;
    const pageSizeString = `&pageSize=${myApprovalsRequest.pageSize}`;
    const orderByString = `&orderBy=${myApprovalsRequest.orderBy}`;
    const sortString = `&desc=${myApprovalsRequest.desc}`;
    const filtersQueryString = getFiltersQueryStringForMyRequests(
        myApprovalsRequest.siteFilters,
        myApprovalsRequest.sourceSystemFilters
    );
    const filtersString = `&filters=${filtersQueryString}`;
    const statusString = `&status=${myApprovalsRequest.myApprovalsStatus}`;
    const myAppprovalsString = `&page=myapprovals`;
    const url = `${baseURL}${startString}${endString}${pageSizeString}${orderByString}${sortString}${filtersString}${statusString}${myAppprovalsString}`;
    return url;
}

export function buildSearchMyRequestsRequestURL(
    baseURL: string,
    searchMyRequestsRequest: SearchMyRequestsAPIRequest
): string {
    const { fieldName, value } = searchMyRequestsRequest;
    const encodedValue = encodeURIComponent(value);
    const startString = `?start=${searchMyRequestsRequest.start}`;
    const endString = `&end=${searchMyRequestsRequest.end}`;
    const pageSizeString = `&pageSize=${searchMyRequestsRequest.pageSize}`;
    const orderByString = `&orderBy=${searchMyRequestsRequest.orderBy}`;
    const sortString = `&desc=${searchMyRequestsRequest.desc}`;
    const filtersQueryString = getFiltersQueryStringForMyRequests(
        searchMyRequestsRequest.siteFilters,
        searchMyRequestsRequest.sourceSystemFilters
    );
    const filtersString = `&filters=${filtersQueryString}`;
    const statusString = `&status=${searchMyRequestsRequest.myRequestsStatus}`;
    const url = `${baseURL}/${fieldName}/${encodedValue}${startString}${endString}${pageSizeString}${orderByString}${sortString}${filtersString}${statusString}`;
    return url;
}

export function buildSearchMyApprovalsRequestURL(
    baseURL: string,
    searchMyApprovalsRequest: SearchMyApprovalsAPIRequest
): string {
    const { fieldName, value } = searchMyApprovalsRequest;
    const encodedValue = encodeURIComponent(value);
    const startString = `?start=${searchMyApprovalsRequest.start}`;
    const endString = `&end=${searchMyApprovalsRequest.end}`;
    const pageSizeString = `&pageSize=${searchMyApprovalsRequest.pageSize}`;
    const orderByString = `&orderBy=${searchMyApprovalsRequest.orderBy}`;
    const sortString = `&desc=${searchMyApprovalsRequest.desc}`;
    const filtersQueryString = getFiltersQueryStringForMyRequests(
        searchMyApprovalsRequest.siteFilters,
        searchMyApprovalsRequest.sourceSystemFilters
    );
    const filtersString = `&filters=${filtersQueryString}`;
    const statusString = `&status=${searchMyApprovalsRequest.myApprovalsStatus}`;
    const myAppprovalsString = `&page=myapprovals`;
    const url = `${baseURL}/${fieldName}/${encodedValue}${startString}${endString}${pageSizeString}${orderByString}${sortString}${filtersString}${statusString}${myAppprovalsString}`;
    return url;
}

export function getFiltersQueryStringForMyRequests(
    siteFilters: string[],
    sourceSystemFilters: string[]
): string {
    const filterArray: JsonItem[] = [];
    if (siteFilters.length > 0) {
        const siteFilterItem = buildFilterListQuery(
            SITE_FILTER.ID,
            siteFilters
        );
        filterArray.push(siteFilterItem);
    }

    if (sourceSystemFilters.length > 0) {
        const sourceSystemFilterItem = buildFilterListQuery(
            SOURCE_SYSTEM_FILTER.ID,
            sourceSystemFilters
        );
        filterArray.push(sourceSystemFilterItem);
    }

    return filterArray.length > 0
        ? encodeURIComponent(JSON.stringify(filterArray))
        : `[]`;
}

export function getFiltersQueryStringForMyApprovals(
    siteFilters: string[],
    sourceSystemFilters: string[]
): string {
    const filterArray: JsonItem[] = [];
    if (siteFilters.length > 0) {
        const siteFilterItem = buildFilterListQuery(
            SITE_FILTER.ID,
            siteFilters
        );
        filterArray.push(siteFilterItem);
    }

    if (sourceSystemFilters.length > 0) {
        const sourceSystemFilterItem = buildFilterListQuery(
            SOURCE_SYSTEM_FILTER.ID,
            sourceSystemFilters
        );
        filterArray.push(sourceSystemFilterItem);
    }

    return filterArray.length > 0
        ? encodeURIComponent(JSON.stringify(filterArray))
        : `[]`;
}

import { AlertBannerProvider } from "@cpchem/covalence-ui";
import { ApprovalDetailsTestIds } from "@pages/tag-management-page/test-ids";
import { TagManagementProvider } from "@stores/tag-management-context";
import React from "react";
import { ApprovalPreview } from "./approval-preview";
import "./styles.scss";

export function ApprovalDetailsView(): JSX.Element {
    return (
        <div
            className="approval-details-view"
            data-testid={ApprovalDetailsTestIds.ApprovalDetailsView}
        >
            <AlertBannerProvider>
                <TagManagementProvider>
                    <ApprovalPreview />
                </TagManagementProvider>
            </AlertBannerProvider>
        </div>
    );
}

import { AccountInformation } from "@cpchem/azure-auth";
import { IngestedTag } from "@services/hermes/model";
import { useOutletContext } from "react-router-dom";

type TagManagementContext = {
    isLoading: boolean;
    onSetLoading: (isLoading: boolean) => void;
    isApprover: boolean;
    ingestedTagData: IngestedTag | null;
    currentAccount: AccountInformation | null;
};

export function useTagManagementContext(): TagManagementContext {
    return useOutletContext<TagManagementContext>();
}

import React, { useContext } from "react";
import { ServiceLocator } from "@services";

export const ServiceContext = React.createContext<typeof ServiceLocator | null>(
    null
);

export function useService<T>(key: string): T {
    const serviceLocator = useContext(ServiceContext);
    if (!serviceLocator) {
        throw new Error("ServiceContext not found");
    }
    return serviceLocator.get<T>(key);
}

interface ServiceProviderProps {
    children: React.ReactNode;
    serviceLocator: typeof ServiceLocator;
}

export function ServiceProvider(props: ServiceProviderProps) {
    return (
        <ServiceContext.Provider value={props.serviceLocator}>
            {props.children}
        </ServiceContext.Provider>
    );
}

import React, { useEffect, useState } from "react";
import "./styles.scss";

interface ConfirmToggleProps {
    isChecked: boolean;
    isDisabled: boolean;
    onChange: (isActive: boolean) => void;
    testId: string;
}
export function ConfirmToggle({
    isChecked,
    isDisabled,
    onChange,
    testId
}: ConfirmToggleProps) {
    const [active, setActive] = useState(false);

    useEffect(() => {
        setActive(isChecked);
    }, [isChecked]);

    const handleClick = () => {
        if (!isDisabled) {
            setActive(!active);
            onChange(!active);
        }
    };

    const handleKeyDown = (event: { key: string }) => {
        if ((!isDisabled && event.key === "Enter") || event.key === " ") {
            handleClick();
        }
    };

    const activeClass = active ? "active" : "";
    const disabledClass = isDisabled ? "disabled" : "";

    return (
        <div
            className={`confirm-toggle ${activeClass} ${disabledClass}`}
            onClick={handleClick}
            onKeyDown={handleKeyDown}
            role="button"
            aria-pressed={active}
            tabIndex={isDisabled ? -1 : 0}
            data-testid={testId}
        >
            <span>C</span>
        </div>
    );
}

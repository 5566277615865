import { TagManagementProvider } from "@stores/tag-management-context";
import React from "react";
import { MyApprovalsTableView } from "./my-approvals-table-view";
import "./styles.scss";
export function MyApprovalsView(): JSX.Element {
    return (
        <div className="my-approvals-view" data-testid="">
            <TagManagementProvider>
                <MyApprovalsTableView />
            </TagManagementProvider>
        </div>
    );
}

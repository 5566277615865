import { Drawer, DrawerLinkGroup } from "@components/drawer";
import { useAuth } from "@cpchem/azure-auth-react";
import {
    faClipboardList,
    faTags,
    faTimer
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TestIds } from "@pages/tag-management-page/test-ids";
import React from "react";
import { routePath } from "../../route-paths";

const approverDrawerLinks: DrawerLinkGroup[] = [
    {
        name: "",
        links: [
            {
                name: "Tag Management",
                url: routePath.TAG_MANAGEMENT,
                icon: <FontAwesomeIcon className="fa-lg" icon={faTags} />,
                key: "tagManagement",
                isExact: true,
                testId: TestIds.AppDrawer.TagManagementLink
            },
            {
                name: "My Requests",
                url: routePath.MY_REQUESTS,
                icon: <FontAwesomeIcon className="fa-lg" icon={faTimer} />,
                key: "myRequests",
                testId: TestIds.AppDrawer.MyRequestsLink
            },
            // {
            //     name: "Power BI",
            //     url: routePath.POWER_BI,
            //     icon: <FontAwesomeIcon className="fa-lg" icon={faStarHalf} />,
            //     key: "powerbi",
            //     testId: TestIds.AppDrawer.PowerBILink
            // },
            {
                name: "My Approvals",
                url: routePath.MY_APPROVALS,
                icon: (
                    <FontAwesomeIcon className="fa-lg" icon={faClipboardList} />
                ),
                key: "myApprovals",
                testId: TestIds.AppDrawer.MyApprovalsLink
            }
        ]
    }
];

const userDrawerLinks: DrawerLinkGroup[] = [
    {
        name: "",
        links: [
            {
                name: "Tag Management",
                url: routePath.TAG_MANAGEMENT,
                icon: <FontAwesomeIcon className="fa-lg" icon={faTags} />,
                key: "tagManagement",
                isExact: true,
                testId: TestIds.AppDrawer.TagManagementLink
            },
            {
                name: "My Requests",
                url: routePath.MY_REQUESTS,
                icon: <FontAwesomeIcon className="fa-lg" icon={faTimer} />,
                key: "myRequests",
                testId: TestIds.AppDrawer.MyRequestsLink
            }
            // {
            //     name: "Power BI",
            //     url: routePath.POWER_BI,
            //     icon: <FontAwesomeIcon className="fa-lg" icon={faStarHalf} />,
            //     key: "powerbi",
            //     testId: TestIds.AppDrawer.PowerBILink
            // }
        ]
    }
];

export function HermesDrawer(): JSX.Element {
    const { currentAccount } = useAuth({});
    const isApprover = currentAccount?.roles?.includes("Hermes.Approver");
    if (isApprover) {
        return <Drawer drawerLinkGroups={approverDrawerLinks} />;
    }
    return <Drawer drawerLinkGroups={userDrawerLinks} />;
}

import { Avatar } from "@cpchem/covalence-ui";
import { HistoryLogViewTestIds } from "@pages/tag-management-page/test-ids";
import { useTagManagement } from "@stores/tag-management-context";
import React, { useEffect, useState } from "react";
import { getOtherGraphUserPhoto } from "../../../../app/components/graph-photo/graph-user-photo";
import { getInitialsFromNameString } from "../../../../app/components/template-app-bar/avatar-content/user-initials-from-name-string";
import "./styles.scss";

export function isInDevEnvironment(env: string): boolean {
    return env.toUpperCase() === "DEVELOPMENT";
}

export const RequestorView = () => {
    const [profilePhoto, setProfilePhoto] = useState<string | null>(null);
    const [userInitials, setUserInitials] = useState<string>("");
    const { state, dispatch } = useTagManagement();
    const { tagRequest } = state;

    async function loadPhoto() {
        if (profilePhoto === null) {
            const photo = tagRequest?.requestedByEmail
                ? await getOtherGraphUserPhoto(tagRequest.requestedByEmail)
                : null;

            setProfilePhoto(photo ?? null);
        }
        if (userInitials === "") {
            const parsedInitials = getInitialsFromNameString(
                tagRequest.requestedBy
            );
            if (parsedInitials !== userInitials) {
                setUserInitials(parsedInitials);
            }
        }
    }

    useEffect(() => {
        loadPhoto();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tagRequest, dispatch]);

    return (
        <>
            <div className="requestor-persona">
                <div
                    className="requestor-header"
                    data-testid={HistoryLogViewTestIds.RequestorHeader}
                >
                    Requestor
                </div>
                <div className="requestor-avatar">
                    {profilePhoto && (
                        <Avatar
                            alt={userInitials}
                            src={profilePhoto || ""}
                            className="avatar"
                            data-testid={HistoryLogViewTestIds.RequestorAvatar}
                        />
                    )}
                    {!profilePhoto && (
                        <Avatar
                            alt={userInitials}
                            initials={userInitials}
                            className="avatar"
                            data-testid={HistoryLogViewTestIds.RequestorAvatar}
                        />
                    )}
                    <p
                        className="requestor-name"
                        data-testid={HistoryLogViewTestIds.RequestorName}
                    >
                        {tagRequest?.requestedBy}
                    </p>
                </div>
            </div>
        </>
    );
};

import { AlertBanner, Grid } from "@cpchem/covalence-ui";
import { ApprovalDetailsView } from "@pages/tag-management-page/components/approval-details-view";
import { CreateNewTagsRequestView } from "@pages/tag-management-page/components/create-new-tags-request-view";
import { MyApprovalsView } from "@pages/tag-management-page/components/my-approvals-view";
import { MyRequestsView } from "@pages/tag-management-page/components/my-requests-view";
import { RequestDetailsView } from "@pages/tag-management-page/components/request-details-view";
import { TagManagementView } from "@pages/tag-management-page/components/tag-management-view";
import { PowerBiView } from "@pages/tag-management-page/components/tag-management-view/powerbi-view";
import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { TagManagementPage } from "../pages";
import { AppBar } from "./components/template-app-bar";
import { routePath } from "./route-paths";

export function App(): JSX.Element {
    return (
        <Grid className="app">
            <AppBar />
            <AlertBanner />
            <div className="main">
                <Routes>
                    <Route
                        path={routePath.TAG_MANAGEMENT}
                        element={<TagManagementPage />}
                    >
                        <Route
                            key={routePath.TAG_MANAGEMENT}
                            index
                            path={routePath.TAG_MANAGEMENT}
                            element={<TagManagementView />}
                        />
                        <Route
                            key={routePath.MY_REQUESTS}
                            path={routePath.MY_REQUESTS}
                            element={<MyRequestsView />}
                        />
                        <Route
                            key={routePath.NEW_REQUEST}
                            path={routePath.NEW_REQUEST}
                            element={<CreateNewTagsRequestView />}
                        />
                        <Route
                            key={routePath.REQUEST_PREVIEW}
                            path={routePath.REQUEST_PREVIEW}
                            element={<RequestDetailsView />}
                        />
                        <Route
                            key={routePath.POWER_BI}
                            path={routePath.POWER_BI}
                            element={<PowerBiView />}
                        />
                        <Route
                            key={routePath.MY_APPROVALS}
                            path={routePath.MY_APPROVALS}
                            element={<MyApprovalsView />}
                        />
                        <Route
                            key={routePath.APPROVAL_PREVIEW}
                            path={routePath.APPROVAL_PREVIEW}
                            element={<ApprovalDetailsView />}
                        />
                    </Route>
                    <Route
                        path="*"
                        element={
                            <Navigate to={routePath.TAG_MANAGEMENT} replace />
                        }
                    />
                </Routes>
            </div>
        </Grid>
    );
}

export default App;

import { Spinner } from "@cpchem/covalence-ui";
import React from "react";
import "./styles.scss";

export type LoadingDisplayProps = {
    /** A boolean value to indicate if the loading component should render the loading state of the component or not. */
    showLoading: boolean;
    /** A text value to place under the spinner image to indicate more context to the loading state. Default is an empty string. */
    loadingMessage?: string;
    /** The content that has to be rendered under the loading container. This can be any undefined. */
    children?: React.ReactNode;
    /** A specific class name that could be used to further customize the main container of the loading component. */
    className?: string;
    /** A boolean value to specify if the content should be rendered while loading or not. */
    hideContentWhileLoading?: boolean;
    /** A test id value to be used with testing tools. */
    testId?: string;
};

export function LoadingDisplay({
    showLoading,
    loadingMessage = "",
    className = "",
    hideContentWhileLoading = false,
    children,
    testId
}: LoadingDisplayProps): JSX.Element {
    const showLoadingOverlay = showLoading ? "disable" : "";
    return (
        <div className={`loading-component ${className}`} data-testid={testId}>
            <div
                className={`loading-content ${showLoadingOverlay} ${
                    showLoading ? "background-animation" : ""
                }`}
            >
                {hideContentWhileLoading && showLoading ? <></> : children}
            </div>
            {showLoading ? (
                <div
                    className="loading-icon image-animation"
                    data-testid={`${testId}-loading-icon`}
                >
                    <div className="icon-image">
                        <Spinner size="x-large" />
                    </div>
                    <div className="message message-animation">
                        {loadingMessage}
                    </div>
                </div>
            ) : (
                <></>
            )}
        </div>
    );
}

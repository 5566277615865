import { ColumnDetailList } from "@components/display/base-header-display/types";

export const MyRequestsColumnList: ColumnDetailList = {
    id: { sortBy: "id", textAlign: "left" },
    requestName: { sortBy: "requestName", textAlign: "left" },
    site: { sortBy: "site", textAlign: "left" },
    source: { sortBy: "source", textAlign: "left" },
    requestedBy: { sortBy: "requestedBy", textAlign: "left" },
    requestedDate: { sortBy: "requestedDate", textAlign: "left" }
};

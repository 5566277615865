import { BaseHeaderContent } from "@components/display/base-header-display/column-groups";
import { DisplaySizeOptions } from "@components/display/display-types";
import {
    DataGrid,
    DataGridColumnDefinition,
    DataGridProps,
    DisplayAmount,
    Pagination
} from "@cpchem/covalence-ui";
import { TagRequest } from "@services/hermes/model";
import { useTagManagement } from "@stores/tag-management-context";
import { sortValue } from "@utilities/api/helper";
import React, { useEffect, useState } from "react";
import { GetColumns } from "../../base-header-display/column-definitions";
import { baseColumns } from "./renderer/base-columns";
import { MyRequestsColumnList } from "./renderer/header/column-details";
import { FilteredEmptyResults, ResultRowFull } from "./renderer/row";

interface MyRequestsDisplayProps {
    myRequests: TagRequest[];
    totalPendingRequestCount: number;
    totalCompleteRequestCount: number;
    totalClosedRequestCount: number;
    rowsToDisplay: DisplayAmount;
    currentPage: number;
    onDisplayPageChange: (value: string) => void;
    goToPage: (value: number) => void;
    onSelectSortValue: (newSort: sortValue, currentSort: sortValue) => void;
    currentSortValue: sortValue;
}

export function MyRequestsDisplay({
    myRequests,
    totalPendingRequestCount,
    totalCompleteRequestCount,
    totalClosedRequestCount,
    rowsToDisplay,
    currentPage,
    onDisplayPageChange,
    goToPage,
    onSelectSortValue,
    currentSortValue
}: MyRequestsDisplayProps) {
    const { state } = useTagManagement();
    const { myRequestsStatus } = state;
    const [displaySizeOptions, setDisplaySizeOptions] =
        useState<DisplaySizeOptions>({
            currentColumns: GetColumns(baseColumns),
            currentRowRenderer: ResultRowFull,
            currentHeaderRenderer: getHeaderRenderer
        });

    let totalMyRequestsCount = 0;
    if (myRequestsStatus === "pending") {
        totalMyRequestsCount = totalPendingRequestCount;
    } else if (myRequestsStatus === "complete") {
        totalMyRequestsCount = totalCompleteRequestCount;
    } else if (myRequestsStatus === "closed") {
        totalMyRequestsCount = totalClosedRequestCount;
    }

    // Initial loading section
    useEffect(() => {
        function defineColumns() {
            setDisplaySizeOptions({
                ...displaySizeOptions,
                currentColumns: GetColumns(baseColumns),
                currentRowRenderer: ResultRowFull,
                currentHeaderRenderer: getHeaderRenderer
            });
        }
        defineColumns();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    // Initial loading section
    useEffect(() => {
        setDisplaySizeOptions({
            ...displaySizeOptions,
            currentHeaderRenderer: getHeaderRenderer
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentSortValue]);

    function getHeaderRenderer(
        headers: DataGridColumnDefinition[]
    ): JSX.Element {
        return BaseHeaderContent(
            headers,
            onSelectSortValue,
            currentSortValue,
            MyRequestsColumnList
        );
    }

    const gridProps: DataGridProps = {
        data: myRequests,
        columns: baseColumns,
        useStickyHeaders: true,
        isSortable: true,
        isFilterable: false,
        headerRenderer: displaySizeOptions.currentHeaderRenderer,
        rowRenderer: displaySizeOptions.currentRowRenderer,
        emptyResultsRenderer: FilteredEmptyResults
    };

    return (
        <div className="my-requests-grid">
            <DataGrid {...gridProps} testId="" />
            <div style={{ width: "100%" }}>
                <Pagination
                    className={"content-center"}
                    totalItems={totalMyRequestsCount}
                    displayAmount={rowsToDisplay}
                    currentPage={currentPage}
                    showItemsPerPageDropDown={totalMyRequestsCount > 10}
                    showDisplayAmountDescription={
                        totalMyRequestsCount > rowsToDisplay
                    }
                    showTotalPagesIndex={false}
                    showNavigationText
                    onDisplayAmountChange={onDisplayPageChange}
                    goToPage={goToPage}
                />
            </div>
        </div>
    );
}

import { SelectOption } from "@cpchem/covalence-ui";

export const buildSelectOptionsList = (
    filterName: string,
    data: string[]
): SelectOption[] => {
    const selectOptions: SelectOption[] = data.map((element: string) => {
        return {
            key: element.trim().toLocaleLowerCase(),
            value: element,
            text: element
        };
    });
    return selectOptions;
};

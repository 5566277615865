import {
    AlertBannerProvider,
    Button,
    Drawer,
    DrawerActions,
    DrawerContent
} from "@cpchem/covalence-ui";
import { faFilter } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FilterListItem, FiltersList, SelectedFilters } from "@models/filters";
import { TestIds } from "@pages/tag-management-page/test-ids";
import {
    AGGREGATION_TYPE_FILTER,
    SITE_FILTER,
    SOURCE_FREQUENCY_FILTER,
    SOURCE_SYSTEM_FILTER,
    STATUS_FILTER
} from "@stores/filters/filter-names";
import { useTagManagement } from "@stores/tag-management-context";
import React, { useState } from "react";
import { FilterBadges, FilterList } from "../index";
import "./styles.scss";

export interface FilterContainerProps {
    applyFiltersDismiss: (id: string, title: string) => void;
    applyFilters: (
        filtersList: FiltersList,
        selectedFilters: SelectedFilters
    ) => void;
    clearFilters: () => void;
    hasActiveFilter: boolean;
    testId?: string;
}

export function FilterContainer({
    applyFilters,
    applyFiltersDismiss,
    clearFilters,
    hasActiveFilter,
    testId
}: FilterContainerProps) {
    const { state } = useTagManagement();
    const [filterDrawerState, setFilterDrawerState] = useState(false);
    const [filterTypesList, setFilterTypesList] = useState<FiltersList>({
        siteFilterList: state.siteFilters,
        sourceSystemFilterList: state.sourceSystemFilters,
        sourceFrequencyFilterList: state.sourceFrequencyFilters,
        aggregationTypeFilterList: state.aggregationTypeFilters,
        statusFilterList: state.statusFilters
    });

    const openFilterDrawer = () => setFilterDrawerState(true);
    const closeFilterDrawer = () => setFilterDrawerState(false);

    const [triggerBadge, setTriggerBadge] = useState(false);

    function onBadgeDismiss(id: string, title: string) {
        applyFiltersDismiss(id, title);
        setTriggerBadge(!triggerBadge);
    }

    const filterConfigs = [
        {
            filters: state.siteFilters,
            title: SITE_FILTER.TITLE,
            testId: TestIds.FilterDrawer.SourceSystemFilterBadge
        },
        {
            filters: state.sourceSystemFilters,
            title: SOURCE_SYSTEM_FILTER.TITLE,
            testId: TestIds.FilterDrawer.SourceSystemFilterBadge
        },
        {
            filters: state.sourceFrequencyFilters,
            title: SOURCE_FREQUENCY_FILTER.TITLE,
            testId: TestIds.FilterDrawer.SourceFrequencyFilterBadge
        },
        {
            filters: state.aggregationTypeFilters,
            title: AGGREGATION_TYPE_FILTER.TITLE,
            testId: TestIds.FilterDrawer.AggregationTypeFilterBadge
        },
        {
            filters: state.statusFilters,
            title: STATUS_FILTER.TITLE,
            testId: TestIds.FilterDrawer.StatusFilterBadge
        }
    ];

    const createFilterListUpdater =
        (filterListKey: keyof FiltersList) => (id: string) => {
            setFilterTypesList((prevFilterTypesList) => ({
                ...prevFilterTypesList,
                [filterListKey]: prevFilterTypesList[filterListKey].map(
                    (filter: FilterListItem) => {
                        if (filter.id === id) {
                            return { ...filter, checked: !filter.checked };
                        }
                        return filter;
                    }
                )
            }));
        };

    const filterListConfigs = [
        {
            title: SITE_FILTER.TITLE,
            showSearch: true,
            filterUpdated: createFilterListUpdater("siteFilterList"),
            filterList: filterTypesList.siteFilterList,
            testId: `${TestIds.FilterDrawer.FilterList}-${SITE_FILTER.ID}`
        },
        {
            title: SOURCE_SYSTEM_FILTER.TITLE,
            showSearch: true,
            filterUpdated: createFilterListUpdater("sourceSystemFilterList"),
            filterList: filterTypesList.sourceSystemFilterList,
            testId: `${TestIds.FilterDrawer.FilterList}-${SOURCE_SYSTEM_FILTER.ID}`
        },
        {
            title: SOURCE_FREQUENCY_FILTER.TITLE,
            showSearch: true,
            filterUpdated: createFilterListUpdater("sourceFrequencyFilterList"),
            filterList: filterTypesList.sourceFrequencyFilterList,
            testId: `${TestIds.FilterDrawer.FilterList}-${SOURCE_FREQUENCY_FILTER.ID}`
        },
        {
            title: AGGREGATION_TYPE_FILTER.TITLE,
            showSearch: true,
            filterUpdated: createFilterListUpdater("aggregationTypeFilterList"),
            filterList: filterTypesList.aggregationTypeFilterList,
            testId: `${TestIds.FilterDrawer.FilterList}-${AGGREGATION_TYPE_FILTER.ID}`
        },
        {
            title: STATUS_FILTER.TITLE,
            showSearch: false,
            filterUpdated: createFilterListUpdater("statusFilterList"),
            filterList: filterTypesList.statusFilterList,
            testId: `${TestIds.FilterDrawer.FilterList}-${STATUS_FILTER.ID}`
        }
    ];

    const getSelectedFilterNames = (filterList: FilterListItem[]) =>
        filterList.filter((x) => x.checked).map((x) => x.name);

    function ApplyFilters() {
        setFilterDrawerState(false);
        const selectedSites = getSelectedFilterNames(
            filterTypesList.siteFilterList
        );
        const selectedSourceSystems = getSelectedFilterNames(
            filterTypesList.sourceSystemFilterList
        );
        const selectedSourceFrequencies = getSelectedFilterNames(
            filterTypesList.sourceFrequencyFilterList
        );
        const selectedAggregationTypes = getSelectedFilterNames(
            filterTypesList.aggregationTypeFilterList
        );
        const selectedStatuses = getSelectedFilterNames(
            filterTypesList.statusFilterList
        );

        const filtersList: FiltersList = {
            siteFilterList: filterTypesList.siteFilterList,
            sourceSystemFilterList: filterTypesList.sourceSystemFilterList,
            sourceFrequencyFilterList:
                filterTypesList.sourceFrequencyFilterList,
            aggregationTypeFilterList:
                filterTypesList.aggregationTypeFilterList,
            statusFilterList: filterTypesList.statusFilterList
        };
        const selectedFiltersList: SelectedFilters = {
            selectedSites,
            selectedSourceSystems,
            selectedSourceFrequencies,
            selectedAggregationTypes,
            selectedStatuses
        };
        applyFilters(filtersList, selectedFiltersList);
    }

    const clearFilterList = (filterList: FilterListItem[]) =>
        filterList.map((filter) => ({
            ...filter,
            checked: false
        }));

    function ClearFilters() {
        setFilterDrawerState(false);
        setFilterTypesList({
            siteFilterList: clearFilterList(filterTypesList.siteFilterList),
            sourceSystemFilterList: clearFilterList(
                filterTypesList.sourceSystemFilterList
            ),
            sourceFrequencyFilterList: clearFilterList(
                filterTypesList.sourceFrequencyFilterList
            ),
            aggregationTypeFilterList: clearFilterList(
                filterTypesList.aggregationTypeFilterList
            ),
            statusFilterList: clearFilterList(filterTypesList.statusFilterList)
        });

        clearFilters();
    }

    return (
        <>
            <div className="hermes-search-filter-badges grid-bar-column">
                <div className="filter-badges">
                    {filterConfigs.map(({ filters, title, testId }) => (
                        <FilterBadges
                            key={title}
                            filters={filters}
                            onDismiss={(id, title) => onBadgeDismiss(id, title)}
                            title={title}
                            testId={testId}
                        />
                    ))}
                    {hasActiveFilter && (
                        <div className="clear-filter-badges">
                            <Button
                                text="Clear Filters"
                                size="medium"
                                variant="text"
                                color="blue"
                                onClick={ClearFilters}
                            />
                        </div>
                    )}
                </div>
            </div>
            <div
                className="hermes-grid-filters grid-bar-column"
                data-testid={testId}
            >
                <div className="grid-filter-button">
                    <Button
                        color="primary"
                        text="Filter"
                        onClick={openFilterDrawer}
                        icon={<FontAwesomeIcon icon={faFilter} />}
                        testId={TestIds.TagManagement.FilterButton}
                    />
                </div>
                <Drawer
                    className="hermes-drawer"
                    isOpen={filterDrawerState}
                    onRequestClose={closeFilterDrawer}
                    title={"Filters"}
                >
                    <div className="hermes-filter-title" />
                    <AlertBannerProvider>
                        <DrawerContent className="hermes-filter-contents">
                            {
                                <div className="hermes-filter-contents-list">
                                    {filterListConfigs.map(
                                        ({
                                            title,
                                            showSearch,
                                            filterUpdated,
                                            filterList,
                                            testId
                                        }) => (
                                            <FilterList
                                                key={title}
                                                title={title}
                                                showSearch={showSearch}
                                                filterUpdated={filterUpdated}
                                                filterList={filterList}
                                                testId={testId}
                                            />
                                        )
                                    )}
                                </div>
                            }
                        </DrawerContent>
                    </AlertBannerProvider>
                    <DrawerActions>
                        <Button
                            text="Clear Filters"
                            onClick={ClearFilters}
                            testId={TestIds.FilterDrawer.ClearFiltersButton}
                        />
                        <Button
                            color="primary"
                            text="Apply Filters"
                            onClick={ApplyFilters}
                            testId={TestIds.FilterDrawer.ApplyFiltersButton}
                        />
                    </DrawerActions>
                </Drawer>
            </div>
        </>
    );
}

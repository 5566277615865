import { NewTagsRequestTestIds } from "@pages/tag-management-page/test-ids";
import React from "react";
export const NewRequestTitleView = () => {
    return (
        <div
            className="header"
            data-testid={NewTagsRequestTestIds.CreateNewRequestViewHeader}
        >
            <p
                className="title"
                data-testid={NewTagsRequestTestIds.CreateNewRequestViewTitle}
            >
                New Request
            </p>
            <p className="welcome-message" data-testid="">
                Select the ‘Site’ and ‘Source System’ before selecting your
                tags. Select ‘Tag Name’ to view all the tags you wish to add.
                Once satisfied, use the ‘Add +’ button to add them to the table.
                When the tags are created on, you can then change the Pull
                Frequency type on each. Once you finished inputting your tags,
                fill out the text area under Business Reason as to why you’re
                requesting a review for these Tags. When your ready to submit,
                select ‘Submit Request’.
            </p>
        </div>
    );
};

import { LogLevel, log } from "@cpchem/logging";

export type ApiResponse<T> =
    | {
          data?: never;
          error: string;
      }
    | {
          data: T;
          error?: never;
      }
    | {
          data?: never;
          error?: never;
      };

export function handle400Response() {
    log(
        `Bad request. Please check your request and try again.`,
        LogLevel.ERROR
    );
    return {
        error: "Bad request. Please check your request and try again."
    };
}

export function handle401Response() {
    log(`You are not authorized to make this call.`, LogLevel.ERROR);
    return {
        error: "You are not authorized to make this call."
    };
}

export function handle404Response() {
    log(
        `Data not found. Please check your request and try again.`,
        LogLevel.ERROR
    );
    return {
        error: "Data not found. Please check your request and try again."
    };
}

export function handle409Response() {
    log(
        `The server states that the request could not be completed due to a conflict with the current state of the target resource.`,
        LogLevel.ERROR
    );
    return {
        error: "The server states that the request could not be completed due to a conflict with the current state of the target resource."
    };
}

export function handle500Response() {
    log(
        `The server encountered an unexpected condition that prevented it from fulfilling the request`,
        LogLevel.ERROR
    );
    return {
        error: "The server encountered an unexpected condition that prevented it from fulfilling the request."
    };
}

export type errorResponseValue = { [key: number]: () => { error: string } };

export const errorResponseDictionary: errorResponseValue = {
    400: handle400Response,
    401: handle401Response,
    404: handle404Response,
    409: handle409Response,
    500: handle500Response
};

import { DataGridColumnDefinition } from "@cpchem/covalence-ui";
import { sortValue } from "@utilities/api/helper";
import React from "react";
import { HeaderCellContent } from "./header-cell-content";
import { ColumnDetailList } from "./types";

export function BaseHeaderContent(
    headers: DataGridColumnDefinition[],
    onSelectSortValue: (newSort: sortValue, currentSort: sortValue) => void,
    currentSortvalue: sortValue,
    columnDetailList: ColumnDetailList
): JSX.Element {
    return (
        <thead className="is-sticky">
            <tr>
                {headers.map((header) => {
                    return (
                        <HeaderCellContent
                            key={header.id}
                            header={header}
                            onSelectSortValue={onSelectSortValue}
                            currentSortValue={currentSortvalue}
                            columnDetailList={columnDetailList}
                        />
                    );
                })}
            </tr>
        </thead>
    );
}

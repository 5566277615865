import { FilterListItem } from "../../models/filters";

export const hasCheckedFilter = (
    siteFilters: FilterListItem[],
    sourceSystemFilters: FilterListItem[],
    sourceFrequencyFilters: FilterListItem[],
    aggregationTypeFilters: FilterListItem[],
    statusFilters: FilterListItem[]
): boolean => {
    let hasCheckedFilter = false;

    siteFilters.filter((item) => {
        if (item.checked === true) {
            hasCheckedFilter = true;
        }
    });
    sourceSystemFilters.filter((item) => {
        if (item.checked === true) {
            hasCheckedFilter = true;
        }
    });
    sourceFrequencyFilters.filter((item) => {
        if (item.checked === true) {
            hasCheckedFilter = true;
        }
    });
    aggregationTypeFilters.filter((item) => {
        if (item.checked === true) {
            hasCheckedFilter = true;
        }
    });
    statusFilters.filter((item) => {
        if (item.checked === true) {
            hasCheckedFilter = true;
        }
    });

    return hasCheckedFilter;
};

import { faFileArrowUp } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useState } from "react";

interface FileUploadProps {
    onFileUpload: (file: File) => void;
}

const FileUpload = ({ onFileUpload }: FileUploadProps) => {
    const [dragOver, setDragOver] = useState(false);
    const [, setFile] = useState<File | null>(null);
    const handleDragEnter = useCallback(
        (e: React.DragEvent<HTMLDivElement>) => {
            e.preventDefault();
            setDragOver(true);
        },
        []
    );

    const handleDragOver = useCallback((e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
    }, []);

    const handleDragLeave = useCallback(
        (e: React.DragEvent<HTMLDivElement>) => {
            e.preventDefault();
            setDragOver(false);
        },
        []
    );

    const handleDrop = useCallback(
        (e: React.DragEvent<HTMLDivElement>) => {
            e.preventDefault();
            setDragOver(false);
            if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
                const uploadedFile = e.dataTransfer.files[0];
                setFile(uploadedFile);
                onFileUpload(uploadedFile);
            }
        },
        [onFileUpload]
    );

    const handleFileChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            if (e.target.files && e.target.files.length > 0) {
                const uploadedFile = e.target.files[0];
                setFile(uploadedFile);
                onFileUpload(uploadedFile);
                e.target.value = "";
            }
        },
        [onFileUpload]
    );

    return (
        <div className="file-upload-container">
            <FontAwesomeIcon icon={faFileArrowUp} className="upload-icon" />
            <div
                className={`${"dropZone"} ${dragOver || ""}`}
                onDragEnter={handleDragEnter}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
            >
                <div>
                    <p>Drag and drop file</p>
                    <p>or</p>
                </div>
            </div>
            <input
                id="file-upload"
                type="file"
                style={{ display: "none" }}
                onChange={handleFileChange}
            />
            <label htmlFor="file-upload" className="browseButton">
                Browse
            </label>
        </div>
    );
};

export default FileUpload;

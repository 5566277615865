/* eslint-disable react-hooks/exhaustive-deps */
import { SearchableDropdown } from "@components/searchable-dropdown/searchable-dropdown";
import { useAuth } from "@cpchem/azure-auth-react";
import {
    AlertOptions,
    Button,
    Modal,
    ModalActions,
    ModalContent,
    Select,
    useAlertBanner
} from "@cpchem/covalence-ui";
import { faPlus } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTagManagementContext } from "@pages/tag-management-page/context";
import { NewTagsRequestTestIds } from "@pages/tag-management-page/test-ids";
import { HermesService, HermesServiceKey } from "@services";
import { InitialFilterState } from "@services/hermes/interface";
import { Tag } from "@services/hermes/model";
import {
    PULL_FREQUENCY_FILTER,
    SITE_FILTER,
    SOURCE_SYSTEM_FILTER,
    TAG_NAME_FILTER
} from "@stores/filters/filter-names";
import {
    defaultNewTag,
    defaultTagRequest,
    useTagManagement
} from "@stores/tag-management-context";
import { buildSelectOptionsList } from "@utilities/filters/build-select-options-list";
import React, { useEffect, useState } from "react";
import { useService } from "../../../../../../service-provider";
import "./styles.scss";

export const NewRequestSelectOptionsView = () => {
    const { currentAccount } = useAuth({});
    const { isLoading, onSetLoading } = useTagManagementContext();
    const hermesService = useService<HermesService>(HermesServiceKey);
    const { state, dispatch } = useTagManagement();
    const { tagRequest } = state;
    const [siteSelected, setSiteSelected] = useState<string | undefined>();
    const [sourceSelected, setSourceSelected] = useState<string | undefined>();
    const [tagNameSelected, setTagNameSelected] = useState<
        string | undefined
    >();
    const { createAlertBanner, dismissAlertBanner } = useAlertBanner();
    const [clearModalVisible, setClearModalVisible] = useState(false);

    const onSiteChanged = (newSite: string | undefined) => {
        dismissAlertBanner();
        setSiteSelectedState(newSite);
        resetSourceAndTagNameSelectOptions();
    };

    function setSiteSelectedState(newSite: string | undefined) {
        setSiteSelected(newSite);
        dispatch({
            type: "SET_SELECTED_SITE",
            payload: {
                selectedSite: newSite
            }
        });
    }

    const onSourceSystemChanged = (newSource: string | undefined) => {
        dismissAlertBanner();
        setSourceSelectedState(newSource);
        resetTagNameSelectOptions();
    };

    function setSourceSelectedState(newSource: string | undefined) {
        setSourceSelected(newSource);
        dispatch({
            type: "SET_SELECTED_SOURCE",
            payload: {
                selectedSource: newSource
            }
        });
    }

    function setTagNameSelectedState(newTagName: string) {
        setTagNameSelected(newTagName);
        dispatch({
            type: "SET_SELECTED_TAG_NAME",
            payload: {
                selectedTagName: newTagName
            }
        });
    }

    const onTagNameChanged = (newTagName: string) => {
        dismissAlertBanner();
        setTagNameSelectedState(newTagName);
    };

    const onAddClicked = () => {
        const newTag: Tag = {
            ...defaultNewTag
        };
        if (
            siteSelected === undefined ||
            sourceSelected === undefined ||
            tagNameSelected === undefined
        ) {
            const alertOptions: AlertOptions = {
                message:
                    "Please select Site, Source System & Tag Name to add a Tag to your request",
                severity: "info",
                dismissable: true
            };
            createAlertBanner(alertOptions);
        } else if (
            tagRequest.items?.length > 0 &&
            tagRequest.items?.some(
                (x) =>
                    x.site === siteSelected &&
                    x.source === sourceSelected &&
                    x.tagName === tagNameSelected
            )
        ) {
            const alertOptions: AlertOptions = {
                message: "Tag selected is already part of this request",
                severity: "caution",
                dismissable: true
            };
            createAlertBanner(alertOptions);
        } else if (
            tagRequest.items?.length > 0 &&
            tagRequest.items?.some(
                (x) => x.site !== siteSelected || x.source !== sourceSelected
            )
        ) {
            const alertOptions: AlertOptions = {
                message:
                    "Request should be unique for a site and source combination",
                severity: "danger",
                dismissable: true
            };
            createAlertBanner(alertOptions);
        } else {
            newTag.site = siteSelected;
            newTag.source = sourceSelected;
            newTag.tagName = tagNameSelected;
            getNewTagMetaData(newTag);
            dismissAlertBanner();
            resetTagNameSelectOptions();
            dispatch({ type: "SET_SEARCH_VALUE", payload: "" });
        }
    };

    async function getNewTagMetaData(newTag: Tag) {
        try {
            onSetLoading(true);
            const tagLookupResults =
                await hermesService.GetNewTagMetadata(newTag);
            if (!tagLookupResults.data) {
                throw new Error(
                    "Lookup failed for selected Site, Source and TagName Combination."
                );
            }
            const data = tagLookupResults.data as Tag;
            setNewTagMetadataState(data);
        } catch (tagLookupError) {
            const error = tagLookupError as Error;
            console.error("Error fetching lookup data: ", error);
            const alertOptions: AlertOptions = {
                message: error.message,
                severity: "danger",
                dismissable: true
            };
            createAlertBanner(alertOptions);
        } finally {
            onSetLoading(false);
        }
    }

    function setNewTagMetadataState(tag: Tag) {
        const currentDate = new Date();
        tag.id = 0;
        tag.enabled = true;
        tag.requestedDate = currentDate.toISOString();
        tag.requestedBy = currentAccount?.name;
        const newItems = [...tagRequest.items, tag];
        setTagRequestData(tag, newItems);
    }

    const onClearClicked = () => {
        if (tagRequest.items?.length === 0) {
            onClearModalConfirmed();
        } else {
            setClearModalVisible(true);
        }
    };

    function setTagRequestData(tag: Tag, newItems: Tag[]) {
        dispatch({
            type: "SET_TAG_REQUEST_DATA",
            payload: {
                tagRequest: {
                    ...tagRequest,
                    site: tag.site,
                    source: tag.source,
                    items: newItems
                }
            }
        });
    }

    function resetTagRequestData() {
        dispatch({
            type: "SET_TAG_REQUEST_DATA",
            payload: {
                tagRequest: defaultTagRequest
            }
        });
    }

    function resetAllSelectOptions() {
        setSiteSelectedState(undefined);
        setSourceSelectedState(undefined);
        setTagNameSelected("");
        setSelectOptionsState(
            SOURCE_SYSTEM_FILTER.TITLE,
            InitialFilterState.filterItems
        );
        setSelectOptionsState(
            TAG_NAME_FILTER.TITLE,
            InitialFilterState.filterItems
        );
        dispatch({ type: "SET_SEARCH_VALUE", payload: "" });
    }

    function resetSourceAndTagNameSelectOptions() {
        // setSourceSelected(undefined);
        setSourceSelectedState(undefined);
        setTagNameSelected("");
        setSelectOptionsState(
            SOURCE_SYSTEM_FILTER.TITLE,
            InitialFilterState.filterItems
        );
        setSelectOptionsState(
            TAG_NAME_FILTER.TITLE,
            InitialFilterState.filterItems
        );
        dispatch({ type: "SET_SEARCH_VALUE", payload: "" });
    }

    function resetTagNameSelectOptions() {
        setTagNameSelectedState("");
    }

    async function getSiteMetadata() {
        try {
            onSetLoading(true);
            const siteMetadataResponse = await hermesService.GetSiteMetadata();

            if (siteMetadataResponse.data) {
                const data = siteMetadataResponse.data as string[];
                setSelectOptionsState(SITE_FILTER.TITLE, data);
            }
        } catch (error) {
            setSelectOptionsState(
                SITE_FILTER.TITLE,
                InitialFilterState.filterItems
            );
        } finally {
            onSetLoading(false);
        }
    }

    async function getSourceSystemMetadata(site: string | undefined) {
        try {
            onSetLoading(true);
            const sourceSystemMetadataResponse =
                await hermesService.GetSourceSystemMetadata(site);

            if (sourceSystemMetadataResponse.data) {
                const data = sourceSystemMetadataResponse.data as string[];
                setSelectOptionsState(SOURCE_SYSTEM_FILTER.TITLE, data);
            }
        } catch (error) {
            setSelectOptionsState(
                SOURCE_SYSTEM_FILTER.TITLE,
                InitialFilterState.filterItems
            );
        } finally {
            onSetLoading(false);
        }
    }

    async function setSelectOptionsState(filterName: string, data: string[]) {
        const selectOptionActions = {
            [SITE_FILTER.TITLE]: () => {
                dispatch({
                    type: "SET_SITE_SELECT_OPTIONS",
                    payload: {
                        siteSelectOptions: buildSelectOptionsList(
                            SITE_FILTER.TITLE,
                            data
                        )
                    }
                });
            },
            [SOURCE_SYSTEM_FILTER.TITLE]: () => {
                dispatch({
                    type: "SET_SOURCE_SYSTEM_SELECT_OPTIONS",
                    payload: {
                        sourceSystemSelectOptions: buildSelectOptionsList(
                            SOURCE_SYSTEM_FILTER.TITLE,
                            data
                        )
                    }
                });
            },
            [TAG_NAME_FILTER.TITLE]: () => {
                dispatch({
                    type: "SET_TAG_NAMES_FOR_SITE_AND_SOURCE",
                    payload: {
                        tagNamesForSiteAndSource: data
                    }
                });
            },
            [PULL_FREQUENCY_FILTER.TITLE]: () => {
                dispatch({
                    type: "SET_PULL_FREQUENCY_SELECT_OPTIONS",
                    payload: {
                        pullFrequencySelectOptions: buildSelectOptionsList(
                            PULL_FREQUENCY_FILTER.TITLE,
                            data
                        )
                    }
                });
            }
        };

        const action = selectOptionActions[filterName];
        if (action) action();
        else console.error("Unknown filterName", filterName);
    }

    async function getPullFrequencyLookupData() {
        try {
            onSetLoading(true);
            const lookupDataResponse = await hermesService.GetLookupData(
                PULL_FREQUENCY_FILTER.ID
            );

            if (lookupDataResponse.data) {
                const data = lookupDataResponse.data as string[];
                setSelectOptionsState(PULL_FREQUENCY_FILTER.TITLE, data);
            }
        } catch (error) {
            console.error(error);
        } finally {
            onSetLoading(false);
        }
    }

    useEffect(() => {
        getSiteMetadata();
        getPullFrequencyLookupData();
    }, []);

    useEffect(() => {
        if (siteSelected) getSourceSystemMetadata(siteSelected);
    }, [siteSelected]);

    function onClearModalClosed() {
        setClearModalVisible(false);
    }

    function onClearModalConfirmed() {
        setClearModalVisible(false);
        resetAllSelectOptions();
        resetTagRequestData();
        dismissAlertBanner();
    }

    function onClearModalCancelled() {
        setClearModalVisible(false);
    }

    const addClearButtonStyle = state.isTagSearchErrorDisplayed
        ? "add-clear-buttons-adjusted"
        : "add-clear-buttons";

    return (
        <>
            {!isLoading && (
                <div
                    className="new-request-select-options-view"
                    data-testid={
                        NewTagsRequestTestIds.NewRequestSelectOptionsView
                    }
                >
                    <Select
                        value={siteSelected}
                        options={state.siteSelectOptions}
                        className="select-site-dropdown"
                        label="Site"
                        onChange={onSiteChanged}
                        isDisabled={tagRequest.items?.length > 0}
                        testId={NewTagsRequestTestIds.SiteSelectOptionsView}
                    />
                    <Select
                        value={sourceSelected}
                        options={state.sourceSystemSelectOptions}
                        className="select-source-dropdown"
                        label="Source System"
                        onChange={onSourceSystemChanged}
                        isDisabled={
                            state.sourceSystemSelectOptions.length === 0 ||
                            tagRequest.items?.length > 0
                        }
                        testId={
                            NewTagsRequestTestIds.SourceSystemSelectOptionsView
                        }
                    />
                    <SearchableDropdown
                        items={state.tagNamesForSiteAndSource}
                        onSelect={onTagNameChanged}
                    />
                    <Button
                        variant="solid"
                        text="Add"
                        className={addClearButtonStyle}
                        color="primary"
                        onClick={onAddClicked}
                        isIconAfterText
                        icon={<FontAwesomeIcon icon={faPlus} />}
                        testId={NewTagsRequestTestIds.AddTagToTableButton}
                    />
                    <Button
                        variant="solid"
                        text="Clear Table"
                        className={addClearButtonStyle}
                        color="danger"
                        onClick={onClearClicked}
                        testId={NewTagsRequestTestIds.ClearTableButton}
                    />
                    <Modal
                        onRequestClose={onClearModalClosed}
                        title="Clear Table?"
                        isOpen={clearModalVisible}
                        className="clear-table-modal"
                        testId={NewTagsRequestTestIds.ClearTableModal}
                    >
                        <ModalContent
                            testId={
                                NewTagsRequestTestIds.ClearTableModalContent
                            }
                        >
                            <div className="clear-table-modal-question">
                                Are you sure you want to clear the table?
                            </div>
                            <div className="clear-table-modal-text">
                                You are going to clear{" "}
                                {tagRequest.items?.length} items from the table.
                            </div>
                        </ModalContent>
                        <ModalActions
                            testId={
                                NewTagsRequestTestIds.ClearTableModalActions
                            }
                        >
                            <Button
                                variant="outline"
                                color="primary"
                                onClick={onClearModalCancelled}
                                text="Cancel"
                                testId={
                                    NewTagsRequestTestIds.ClearTableModalCancelButton
                                }
                            />
                            <Button
                                color="danger"
                                onClick={onClearModalConfirmed}
                                text="Clear Table"
                                testId={
                                    NewTagsRequestTestIds.ClearTableModalConfirmButton
                                }
                            />
                        </ModalActions>
                    </Modal>
                </div>
            )}
        </>
    );
};

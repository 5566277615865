import { AppIcon } from "@components/icons/app-icon";
import { HelpPageIcon } from "@components/icons/help-page-icon";
import config, { buildConfig } from "@config";
import { AuthManager } from "@cpchem/azure-auth";
import { useAuth } from "@cpchem/azure-auth-react";
import {
    Button,
    AppBar as CovalenceAppBar,
    EnvironmentIndicator,
    Modal,
    ModalContent
} from "@cpchem/covalence-ui";
import { faSquareInfo } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TestIds } from "@pages/tag-management-page/test-ids";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { routePath } from "../../route-paths";
import { getGraphUserPhoto } from "../graph-photo/graph-photo";
import { version } from "./../../../../package.json";
import { getAvatarContent } from "./avatar-content/avatar-content";
import { getInitialsFromName } from "./avatar-content/user-initials";
import "./styles.scss";

export function isInDevEnvironment(env: string): boolean {
    return env.toUpperCase() === "DEVELOPMENT";
}

export function AppBar(): JSX.Element {
    const { viteBuildDate } = buildConfig;
    const buildDate = new Date(viteBuildDate);
    const buildTimeStamp = buildDate.toLocaleString().replace(",", "");
    const navigate = useNavigate();
    const [profilePhoto, setProfilePhoto] = useState<string | null>(null);
    const [userInitials, setUserInitials] = useState<string>("");
    const [helpModalVisible, setHelpModalVisible] = useState(false);
    const authMgr = AuthManager.getInstance();
    const { currentAccount } = useAuth({});

    function handleIconClick() {
        navigate(routePath.LANDING);
    }

    function isNonProduction(): boolean {
        const windowHostname = window.location.hostname;

        if (
            windowHostname.includes("localhost") ||
            windowHostname.includes("test.") ||
            windowHostname.includes("dev.")
        ) {
            return true;
        }

        return false;
    }

    const showEnvironmentIndicator = isNonProduction();

    async function loadCurrentUserPhoto() {
        if (profilePhoto === null) {
            setProfilePhoto(await getGraphUserPhoto(profilePhoto));
        }
    }

    function loadUserInitials() {
        if (userInitials === "") {
            const parsedInitials = getInitialsFromName(currentAccount);
            if (parsedInitials !== userInitials) {
                setUserInitials(parsedInitials);
            }
        }
    }

    function onHelpModalClosed() {
        setHelpModalVisible(false);
    }

    function onHelpPageItemClicked() {
        setHelpModalVisible(true);
    }

    const helpMenuContent = (
        <div className="feed-back-menu">
            <Button
                text={"Help Page"}
                icon={<FontAwesomeIcon icon={faSquareInfo} className="icon" />}
                variant="text"
                onClick={onHelpPageItemClicked}
                testId={TestIds.TagManagement.HelpPageButton}
            />
        </div>
    );

    useEffect(() => {
        loadUserInitials();
        if (
            (isInDevEnvironment(config.env) && config.connected) ||
            !isInDevEnvironment(config.env)
        ) {
            loadCurrentUserPhoto();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {showEnvironmentIndicator && (
                <EnvironmentIndicator
                    text="Non-Production System"
                    testId={TestIds.TagManagement.EnvIndicator}
                />
            )}

            <CovalenceAppBar
                className="appbar"
                applicationName="Hermes Portal"
                icon={<AppIcon />}
                onIconClick={handleIconClick}
                testId={TestIds.TagManagement.AppName}
                helpMenuItems={[
                    {
                        key: "helpMenu",
                        content: helpMenuContent,
                        testId: TestIds.TagManagement.HelpPage
                    }
                ]}
                avatarMenu={{
                    avatar: getAvatarContent(
                        profilePhoto,
                        userInitials,
                        currentAccount,
                        TestIds.TagManagement.AvatarProfile
                    ),
                    userName: currentAccount ? currentAccount?.username : "",
                    displayName: currentAccount ? currentAccount?.name : "",
                    showLogout: true,
                    showThemeSelector: false,
                    onRequestLogout: authMgr.logout,
                    testId: TestIds.TagManagement.AvatarMenu
                }}
            />
            <Modal
                onRequestClose={onHelpModalClosed}
                title="Hermes Portal"
                isOpen={helpModalVisible}
                className="help-modal"
                testId={TestIds.TagManagement.HelpPageModal}
            >
                <ModalContent testId="">
                    <div className="help-page-content">
                        <div className="help-page-image">
                            <HelpPageIcon />
                        </div>
                        <div className="help-page-version-build-info">
                            <div className="build-version-timestamp">
                                <p className="build-version-date-text">
                                    Version
                                </p>
                                <p
                                    data-testid={
                                        TestIds.TagManagement.AppVersion
                                    }
                                >
                                    {version}
                                </p>
                            </div>
                            <div className="build-version-timestamp">
                                <p className="build-version-date-text">
                                    Build Date
                                </p>
                                <p
                                    data-testid={
                                        TestIds.TagManagement.AppBuildDate
                                    }
                                >
                                    {buildTimeStamp}
                                </p>
                            </div>
                        </div>
                        <div className="help-page-digital-workplace-hyperlink">
                            <p>
                                For more help, please utilize the{" "}
                                <a
                                    href="https://cpchem-myit.onbmc.com/dwp/"
                                    data-testid={
                                        TestIds.TagManagement
                                            .DigitalWorkplaceLink
                                    }
                                >
                                    Digital Workplace Portal
                                </a>
                            </p>
                        </div>
                        <div className="help-page-copyright-text">
                            <p>
                                Copyright © 2024 by Chevron Phillips Chemical.
                                Company Confidential. For Internal Use Only.
                            </p>
                        </div>
                    </div>
                </ModalContent>
            </Modal>
        </>
    );
}

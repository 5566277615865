import { GetColumns } from "@components/display/base-header-display/column-definitions";
import { BaseHeaderContent } from "@components/display/base-header-display/column-groups";
import { DisplaySizeOptions } from "@components/display/display-types";
import {
    DataGrid,
    DataGridColumnDefinition,
    DataGridProps,
    DisplayAmount,
    Pagination
} from "@cpchem/covalence-ui";
import { TestIds } from "@pages/tag-management-page/test-ids";
import { IngestedTag } from "@services/hermes/model";
import { sortValue } from "@utilities/api/helper";
import React, { useEffect, useState } from "react";
import { baseColumns } from "./renderer/base-columns";
import { IngestTagColumnList } from "./renderer/header/column-details";
import { FilteredEmptyResults, ResultRowFull } from "./renderer/row";

interface IngestedTagsDisplayProps {
    ingestedTags: IngestedTag[];
    totalIngestedTagCount: number;
    rowsToDisplay: DisplayAmount;
    currentPage: number;
    onDisplayPageChange: (value: string) => void;
    goToPage: (value: number) => void;
    onSelectSortValue: (newSort: sortValue, currentSort: sortValue) => void;
    currentSortValue: sortValue;
}

export function IngestedTagsDisplay({
    ingestedTags,
    totalIngestedTagCount,
    rowsToDisplay,
    currentPage,
    onDisplayPageChange,
    goToPage,
    onSelectSortValue,
    currentSortValue
}: IngestedTagsDisplayProps) {
    const [displaySizeOptions, setDisplaySizeOptions] =
        useState<DisplaySizeOptions>({
            currentColumns: GetColumns(baseColumns),
            currentRowRenderer: ResultRowFull,
            currentHeaderRenderer: getHeaderRenderer
        });

    // Initial loading section
    useEffect(() => {
        function defineColumns() {
            setDisplaySizeOptions({
                ...displaySizeOptions,
                currentColumns: GetColumns(baseColumns),
                currentRowRenderer: ResultRowFull,
                currentHeaderRenderer: getHeaderRenderer
            });
        }
        defineColumns();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Initial loading section
    useEffect(() => {
        setDisplaySizeOptions({
            ...displaySizeOptions,
            currentHeaderRenderer: getHeaderRenderer
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentSortValue]);

    function getHeaderRenderer(
        headers: DataGridColumnDefinition[]
    ): JSX.Element {
        return BaseHeaderContent(
            headers,
            onSelectSortValue,
            currentSortValue,
            IngestTagColumnList
        );
    }

    const gridProps: DataGridProps = {
        data: ingestedTags,
        columns: baseColumns,
        useStickyHeaders: true,
        isSortable: true,
        isFilterable: false,
        headerRenderer: displaySizeOptions.currentHeaderRenderer,
        rowRenderer: displaySizeOptions.currentRowRenderer,
        emptyResultsRenderer: FilteredEmptyResults
    };

    return (
        <div className="ingested-tags-grid">
            <DataGrid
                {...gridProps}
                testId={TestIds.IngestedTags.IngestedTagsGrid}
            />
            <div style={{ width: "100%" }}>
                <Pagination
                    className={"content-center"}
                    totalItems={totalIngestedTagCount}
                    displayAmount={rowsToDisplay}
                    currentPage={currentPage}
                    showItemsPerPageDropDown={totalIngestedTagCount > 10}
                    showDisplayAmountDescription={
                        totalIngestedTagCount > rowsToDisplay
                    }
                    showTotalPagesIndex={false}
                    showNavigationText
                    onDisplayAmountChange={onDisplayPageChange}
                    goToPage={goToPage}
                />
            </div>
        </div>
    );
}

import { AlertBannerProvider } from "@cpchem/covalence-ui";
import { RequestDetailsTestIds } from "@pages/tag-management-page/test-ids";
import { TagManagementProvider } from "@stores/tag-management-context";
import React from "react";
import { RequestPreview } from "./request-preview";
import "./styles.scss";
export function RequestDetailsView(): JSX.Element {
    return (
        <div
            className="request-details-view"
            data-testid={RequestDetailsTestIds.RequestDetailsView}
        >
            <AlertBannerProvider>
                <TagManagementProvider>
                    <RequestPreview />
                </TagManagementProvider>
            </AlertBannerProvider>
        </div>
    );
}

export function getInitialsFromNameString(userName: string | null): string {
    if (userName !== null) {
        if (userName && userName.length > 0) {
            const userLetters = userName.split(" ");
            return `${userLetters[1].substring(
                0,
                1
            )}${userLetters[0].substring(0, 1)}`;
        }
    }

    return "";
}

import { TestIds } from "@pages/tag-management-page/test-ids";
import React from "react";
import { TagManagementProvider } from "../../../../stores/tag-management-context";
import "./styles.scss";
import { TagManagementTableView } from "./tag-management-table-view";
export function TagManagementView(): JSX.Element {
    return (
        <div
            className="tag-management-view"
            data-testid={TestIds.TagManagement.TagManagementView}
        >
            <TagManagementProvider>
                <TagManagementTableView showInfoMessage />
            </TagManagementProvider>
        </div>
    );
}

import { TagManagementProvider } from "@stores/tag-management-context";
import React from "react";
import { MyRequestsTableView } from "./my-requests-table-view";
import "./styles.scss";
export function MyRequestsView(): JSX.Element {
    return (
        <div className="my-requests-view" data-testid="">
            <TagManagementProvider>
                <MyRequestsTableView />
            </TagManagementProvider>
        </div>
    );
}

import {
    AlertOptions,
    Button,
    DataGridRow,
    Select,
    useAlertBanner
} from "@cpchem/covalence-ui";
import { faPen, faTrash } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NewTagsRequestTestIds } from "@pages/tag-management-page/test-ids";
import { Tag } from "@services/hermes/model";
import { useTagManagement } from "@stores/tag-management-context";
import React, { useState } from "react";
import "./styles.scss";

interface TagRowProps {
    row: DataGridRow;
    deleteTagRow: (tagName: string | undefined) => void;
}

export const TagRow = ({ row, deleteTagRow }: TagRowProps) => {
    const tag = row.values as Tag;
    const { state, dispatch } = useTagManagement();
    const { tagRequest } = state;
    const { createAlertBanner, dismissAlertBanner } = useAlertBanner();
    const [isEditing, setIsEditing] = useState(false);
    const [editedTag, setEditedTag] = useState(tag);
    const [commentRequiredError, setCommentRequiredError] = useState(false);
    let tagRowStyle = "";

    if (isEditing) {
        tagRowStyle = "tag-review-row-edited";
    } else {
        tagRowStyle = "tag-review-row-pending";
    }

    const saveChanges = () => {
        if (
            editedTag.pullFrequency !== tag.pullFrequency &&
            !editedTag.comments
        ) {
            setCommentRequiredError(true);
        } else {
            tagRequest.items.map((tag) => {
                if (tag.tagName === editedTag.tagName) {
                    tag.pullFrequency = editedTag.pullFrequency;
                    tag.comments = editedTag.comments;
                }
            });

            dispatch({
                type: "SET_TAG_REQUEST_DATA",
                payload: {
                    tagRequest: tagRequest
                }
            });
            dismissAlertBanner();
            const alertOptions: AlertOptions = {
                message: `Source Frequency for tag: ${editedTag.tagName} updated to ${editedTag.pullFrequency}`,
                severity: "success",
                dismissable: true
            };
            createAlertBanner(alertOptions);
            setIsEditing(false);
            setCommentRequiredError(false);
        }
    };

    const textAreaStyle: React.CSSProperties = commentRequiredError
        ? { borderColor: "var(--danger)" }
        : { borderColor: "#767676" };

    const cancelEdit = () => {
        setCommentRequiredError(false);
        setIsEditing(false);
        setEditedTag(tag);
    };

    const handleEditChange = (field: string, value: string) => {
        if (
            field === "comments" &&
            editedTag.pullFrequency !== tag.pullFrequency
        ) {
            value
                ? setCommentRequiredError(false)
                : setCommentRequiredError(true);
        }
        setEditedTag((prev) => ({ ...prev, [field]: value }));
    };

    const key = Number(row.id) + 1;
    return (
        <>
            <tr key={key} className={tagRowStyle}>
                <td className="items-cell">{key}</td>
                <td className="items-cell">{tag.site}</td>
                <td className="items-cell">{tag.source}</td>
                <td className="items-cell">{tag.tagName}</td>
                <td className="items-cell">{tag.engUnits}</td>
                <td className="items-cell">{tag.sourceFrequency}</td>
                <td className="items-cell">{tag.aggregationType}</td>
                {isEditing && (
                    <td className="items-cell">
                        <Select
                            value={editedTag.pullFrequency || tag.pullFrequency}
                            options={state.pullFrequencySelectOptions}
                            className="select-dropdown"
                            onChange={(pullFrequency) =>
                                handleEditChange("pullFrequency", pullFrequency)
                            }
                            testId={
                                NewTagsRequestTestIds.PullFrequencySelectOptionsView
                            }
                            isLabelHidden
                            label={""}
                        />
                    </td>
                )}
                {!isEditing && (
                    <td className="items-cell">{editedTag.pullFrequency}</td>
                )}
                <td style={{ textAlign: "center" }}>
                    {!isEditing && (
                        <FontAwesomeIcon
                            icon={faPen}
                            className="edit-tag"
                            size="sm"
                            onClick={() => setIsEditing(true)}
                            data-testid={`${NewTagsRequestTestIds.EditTagIcon}-${key}`}
                        />
                    )}
                </td>
                <td style={{ textAlign: "center" }}>
                    <FontAwesomeIcon
                        icon={faTrash}
                        className="delete-tag"
                        size="lg"
                        onClick={() => deleteTagRow(tag.tagName)}
                        data-testid={`${NewTagsRequestTestIds.DeleteTagIcon}-${key}`}
                    />
                </td>
            </tr>
            {isEditing && (
                <tr className="edit-container-row">
                    <td colSpan={10}>
                        <div className="edit-container">
                            <div className="edit-tag-text-area-container">
                                <div className="edit-tag-text-area-panel">
                                    <p>Tag Notes</p>
                                    {commentRequiredError && (
                                        <p className="comment-required">
                                            &nbsp; *Reason for change to Pull
                                            Frequency
                                        </p>
                                    )}
                                </div>
                                <textarea
                                    placeholder="Leave a summary of why you’re submitting changes here..."
                                    style={textAreaStyle}
                                    title="Reason for change"
                                    onChange={(e) =>
                                        handleEditChange(
                                            "comments",
                                            e.target.value
                                        )
                                    }
                                    className="edit-tag-text-area"
                                />
                            </div>
                            <div className="edit-tag-panel-buttons">
                                <Button
                                    variant="outline"
                                    size="medium"
                                    text="Cancel"
                                    className="cancel-tag-edit"
                                    color="secondary"
                                    onClick={cancelEdit}
                                    testId={`${NewTagsRequestTestIds.CancelTagEdit}-${key}`}
                                />
                                <Button
                                    variant="solid"
                                    size="medium"
                                    text="Save"
                                    className="save-tag-edit"
                                    color="primary"
                                    onClick={saveChanges}
                                    testId={`${NewTagsRequestTestIds.SaveTagEdit}-${key}`}
                                />
                            </div>
                        </div>
                    </td>
                </tr>
            )}
        </>
    );
};

export interface JsonItem {
    name: string;
    values: string;
}

export function buildFilterListQuery(
    fieldName: string,
    items: string[]
): JsonItem {
    const jsonObject: JsonItem = {
        name: fieldName,
        values: items.join(",")
    };
    return jsonObject;
}

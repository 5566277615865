import { SearchBox } from "@cpchem/covalence-ui";
import { TestIds } from "@pages/tag-management-page/test-ids";
import React, { useState } from "react";
import { useTagManagement } from "../../../stores/tag-management-context";

interface HermesSearchBoxProps {
    placeHolder?: string;
    toolTip?: string;
}
export function HermesSearchBox({
    placeHolder,
    toolTip
}: HermesSearchBoxProps): JSX.Element {
    const { state, dispatch } = useTagManagement();
    const [searchTerm, setSearchTerm] = useState<string>(state.searchValue);
    const [errorMessage, setErrorMessage] = useState("");

    function resetSearchResults() {
        if (searchTerm && searchTerm.length < 3) {
            setErrorMessage("Search term should at least be three characters.");
        } else if (searchTerm.length >= 3) {
            setSearchValueState(searchTerm);
        }
    }

    function onSearchValueChange(value: string) {
        setErrorMessage("");
        setSearchTerm(value);
        if (!value) {
            setSearchValueState(value);
        }
    }

    function setSearchValueState(value: string) {
        const trimmedValue = value.trim();
        dispatch({ type: "SET_SEARCH_VALUE", payload: trimmedValue });
    }

    return (
        <SearchBox
            label=""
            value={searchTerm}
            description=""
            className=""
            showLeftIcon
            placeholder={placeHolder}
            onPressEnter={resetSearchResults}
            tooltip={toolTip}
            onChange={onSearchValueChange}
            testId={TestIds.TagManagement.SearchBox}
            errorMessage={errorMessage}
        />
    );
}

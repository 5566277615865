import { Badge } from "@cpchem/covalence-ui";
import {
    faChevronDown,
    faChevronUp,
    faClock
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { HermesAccordionTestIds } from "@pages/tag-management-page/test-ids";
import React, { useState } from "react";
import "./styles.scss";

interface HermesAccordionProps {
    title?: string;
    status: string;
    lastUpdated?: string;
    children: React.ReactNode;
}

function HermesAccordion({
    title,
    status,
    lastUpdated,
    children
}: HermesAccordionProps) {
    const [isOpen, setIsOpen] = useState(true);
    const toggleAccordion = () => setIsOpen(!isOpen);
    const AccordionIcon = isOpen ? faChevronUp : faChevronDown;

    return (
        <>
            <div className="accordion">
                <div className="accordion-header">
                    <div
                        className="accordion-title"
                        data-testid={HermesAccordionTestIds.AccordionTitle}
                    >
                        {title}
                    </div>
                    <Badge
                        text={status}
                        size="medium"
                        color="blue"
                        variant="subtle"
                        className="accordion-badge"
                        testId={HermesAccordionTestIds.Badge}
                    />
                    <button
                        onClick={toggleAccordion}
                        className="accordion-toggle"
                        data-testid={HermesAccordionTestIds.ToggleButton}
                    >
                        {<FontAwesomeIcon icon={AccordionIcon} />}
                    </button>
                </div>

                {isOpen && <div className="accordion-content">{children}</div>}
            </div>
            {isOpen && (
                <div
                    className="accordion-footer"
                    data-testid={HermesAccordionTestIds.AccordionFooter}
                >
                    {
                        <FontAwesomeIcon
                            icon={faClock}
                            inverse
                            className="accordion-footer-icon"
                            size="xs"
                        />
                    }
                    <div
                        className="accordion-footer-text"
                        data-testid={HermesAccordionTestIds.FooterText}
                    >
                        {lastUpdated}
                    </div>
                </div>
            )}
        </>
    );
}

export default HermesAccordion;

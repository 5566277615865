import {
    DataGridColumnDefinition,
    FilteringDataGridColumnDefinition
} from "@cpchem/covalence-ui";

export const actionsColumn: DataGridColumnDefinition = {
    Header: "Actions",
    disableSortBy: true,
    alignment: "center"
};

export function GetColumns(
    baseColumns: (
        | DataGridColumnDefinition
        | FilteringDataGridColumnDefinition
    )[]
) {
    const columnsDefinition: (
        | DataGridColumnDefinition
        | FilteringDataGridColumnDefinition
    )[] = [...baseColumns];
    columnsDefinition.push(actionsColumn);
    return columnsDefinition;
}

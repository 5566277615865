import { AccountInformation } from "@cpchem/azure-auth";
import { AvatarProps } from "@cpchem/covalence-ui";

export function getAvatarContent(
    profilePhoto: string | null,
    userInitials: string,
    currentAccount: AccountInformation | null,
    testId?: string
): AvatarProps {
    if (profilePhoto !== null && profilePhoto !== "") {
        testId = testId !== null ? `${testId}-photo` : testId;
        return {
            alt: currentAccount ? currentAccount.name ?? "" : "",
            src: profilePhoto ?? "",
            testId
        };
    }
    if (userInitials !== "") {
        testId = testId !== null ? `${testId}-text` : testId;
        return {
            alt: currentAccount ? currentAccount.name ?? "" : "",
            initials: userInitials,
            testId
        };
    }

    testId = testId !== null ? `${testId}-icon` : testId;
    return { testId };
}

import { DataGridColumnSortState } from "@components/display/base-header-display/constants";
import { useTagManagementContext } from "@pages/tag-management-page/context";
import { TestIds } from "@pages/tag-management-page/test-ids";
import { HermesServiceKey } from "@services";
import {
    GetIngestedTagsAPIRequest,
    HermesService,
    InitialFilterState,
    InitialIngestedTagsDataState,
    SearchIngestedTagsAPIRequest
} from "@services/hermes/interface";
import { IngestedTagsData } from "@services/hermes/model";
import {
    AGGREGATION_TYPE_FILTER,
    FilterType,
    SITE_FILTER,
    SOURCE_FREQUENCY_FILTER,
    SOURCE_SYSTEM_FILTER,
    STATUS_FILTER
} from "@stores/filters/filter-names";
import { buildFilterListItemList } from "@utilities/filters/build-filter-list-item-list";
import { getSelectedFiltersFromList } from "@utilities/filters/set-selected-filters-from-list-on-load";
import React, { useEffect } from "react";
import { useService } from "../../../../../service-provider";
import { useTagManagement } from "../../../../../stores/tag-management-context";
import { IngestedTagsTableView } from "../ingested-tags-table-view";

interface TagManagementTableViewProps {
    showInfoMessage?: boolean;
}

export function TagManagementTableView({
    showInfoMessage
}: TagManagementTableViewProps): JSX.Element {
    const { state, dispatch } = useTagManagement();
    const hermesService = useService<HermesService>(HermesServiceKey);
    const ingestedTagsRequest: GetIngestedTagsAPIRequest = {
        start: state.currentPage,
        end: state.currentPage,
        pageSize: state.rowsToDisplay,
        siteFilters: getSelectedFiltersFromList(state.siteFilters),
        sourceSystemFilters: getSelectedFiltersFromList(
            state.sourceSystemFilters
        ),
        sourceFrequencyFilters: getSelectedFiltersFromList(
            state.sourceFrequencyFilters
        ),
        aggregationTypeFilters: getSelectedFiltersFromList(
            state.aggregationTypeFilters
        ),
        statusFilters: getSelectedFiltersFromList(state.statusFilters),
        orderBy: state.sortValue.column.toLowerCase(),
        desc: state.sortValue.sort === DataGridColumnSortState.DESC
    };
    const searchTagsRequest: SearchIngestedTagsAPIRequest = {
        fieldName: state.searchFieldName || "tagName",
        value: state.searchValue,
        start: state.currentPage,
        end: state.currentPage,
        pageSize: state.rowsToDisplay,
        siteFilters: getSelectedFiltersFromList(state.siteFilters),
        sourceSystemFilters: getSelectedFiltersFromList(
            state.sourceSystemFilters
        ),
        sourceFrequencyFilters: getSelectedFiltersFromList(
            state.sourceFrequencyFilters
        ),
        aggregationTypeFilters: getSelectedFiltersFromList(
            state.aggregationTypeFilters
        ),
        statusFilters: getSelectedFiltersFromList(state.statusFilters),
        orderBy: state.sortValue.column.toLowerCase() || "site",
        desc: state.sortValue.sort === DataGridColumnSortState.DESC
    };
    const { isLoading, onSetLoading } = useTagManagementContext();

    async function getIngestedTags() {
        if (state.sortValue.sort === DataGridColumnSortState.WAIT) return;
        try {
            let response;
            onSetLoading(true);

            if (!state.searchValue) {
                response =
                    await hermesService.GetIngestedTags(ingestedTagsRequest);
            } else {
                response =
                    await hermesService.SearchIngestedTags(searchTagsRequest);
            }

            if (response.data) {
                const data = response.data as IngestedTagsData;
                setIngestedTagsState(data);
            }
        } catch (error) {
            setIngestedTagsState(InitialIngestedTagsDataState);
        } finally {
            onSetLoading(false);
        }
    }

    function setIngestedTagsState(data: IngestedTagsData) {
        dispatch({
            type: "SET_INGESTED_TAGS_DATA",
            payload: {
                totalIngestedTagCount: data.totalCount,
                ingestedTags: data.items
            }
        });
    }

    async function setFiltersState(filterName: string, data: string[]) {
        const filterActions = {
            [SITE_FILTER.TITLE]: () => {
                dispatch({
                    type: "SET_SITE_FILTER",
                    payload: {
                        siteFilters: buildFilterListItemList(
                            data,
                            filterName,
                            state.siteFilters ?? []
                        )
                    }
                });
            },
            [SOURCE_SYSTEM_FILTER.TITLE]: () => {
                dispatch({
                    type: "SET_SOURCE_SYSTEM_FILTER",
                    payload: {
                        sourceSystemFilters: buildFilterListItemList(
                            data,
                            filterName,
                            state.sourceSystemFilters ?? []
                        )
                    }
                });
            },
            [SOURCE_FREQUENCY_FILTER.TITLE]: () => {
                dispatch({
                    type: "SET_SOURCE_FREQUENCY_FILTER",
                    payload: {
                        sourceFrequencyFilters: buildFilterListItemList(
                            data,
                            filterName,
                            state.sourceFrequencyFilters ?? []
                        )
                    }
                });
            },
            [AGGREGATION_TYPE_FILTER.TITLE]: () => {
                dispatch({
                    type: "SET_AGGREGATION_TYPE_FILTER",
                    payload: {
                        aggregationTypeFilters: buildFilterListItemList(
                            data,
                            filterName,
                            state.aggregationTypeFilters ?? []
                        )
                    }
                });
            },
            [STATUS_FILTER.TITLE]: () => {
                dispatch({
                    type: "SET_STATUS_FILTER",
                    payload: {
                        statusFilters: buildFilterListItemList(
                            data,
                            filterName,
                            state.statusFilters ?? []
                        )
                    }
                });
            }
        };

        const action = filterActions[filterName];
        if (action) action();
        else console.error("Unknown filterName", filterName);
    }

    async function getFilterItems(filterType: FilterType) {
        try {
            const filterResponse =
                await hermesService.GetFilterItemsForFilterID(filterType.ID);

            if (filterResponse.data) {
                const data = filterResponse.data as string[];
                setFiltersState(filterType.TITLE, data);
            }
        } catch (error) {
            setFiltersState(filterType.TITLE, InitialFilterState.filterItems);
        }
    }

    async function getAllInitialFilterItems() {
        getFilterItems(SITE_FILTER);
        getFilterItems(SOURCE_SYSTEM_FILTER);
        getFilterItems(SOURCE_FREQUENCY_FILTER);
        getFilterItems(AGGREGATION_TYPE_FILTER);
        getFilterItems(STATUS_FILTER);
    }

    useEffect(() => {
        getIngestedTags();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        state.currentPage,
        state.rowsToDisplay,
        state.sortValue,
        state.searchFieldName,
        state.searchValue,
        state.siteFilters,
        state.sourceSystemFilters,
        state.sourceFrequencyFilters,
        state.aggregationTypeFilters,
        state.statusFilters,
        dispatch
    ]);

    useEffect(() => {
        getAllInitialFilterItems();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="tag-management-table-view">
            <div className="tag-management-table">
                <div className="configured-tags">
                    {showInfoMessage && (
                        <div
                            className="header"
                            data-testid={`${TestIds.TagManagement.TagManagementTableViewHeader}`}
                        >
                            <p
                                className="title"
                                data-testid={`${TestIds.TagManagement.TagManagementTableViewTitle}`}
                            >
                                Tag Management
                            </p>
                            <p
                                className="welcome-message"
                                data-testid={`${TestIds.TagManagement.TagManagementTableViewWelcomeMessage}`}
                            >
                                Welcome to Hermes Tag Management Portal. Shown
                                below are all the available tags in the system.
                            </p>
                        </div>
                    )}
                    {!isLoading && <IngestedTagsTableView />}
                </div>
            </div>
        </div>
    );
}

import {
    Accordion,
    AlertBanner,
    Checkbox,
    SearchBox,
    useAlertBanner
} from "@cpchem/covalence-ui";
import { TestIds } from "@pages/tag-management-page/test-ids";
import React, { useEffect, useState } from "react";
import { FilterListItem } from "../../../models";
import "./styles.scss";

export interface FilterListProps {
    filterList: FilterListItem[];
    title: string;
    filterUpdated: (id: string) => void;
    showSearch: boolean;
    defaultOpen?: boolean;
    showAlert?: boolean;
    testId?: string;
}

export function FilterList({
    filterList,
    title,
    filterUpdated,
    showSearch,
    defaultOpen,
    showAlert,
    testId
}: FilterListProps): JSX.Element {
    const [filterText, setFilterText] = useState("");
    const { createAlertBanner } = useAlertBanner();

    useEffect(() => {
        createAlertBanner({
            severity: "info",
            message:
                "Didn't find what you were looking for? Use the search to find more."
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    const placeholder = "Type at least 2 characters to search";

    const filteredOptions = filterList.filter(({ name }) => {
        if (filterText.length <= 1) {
            return filterList;
        }
        const regex = new RegExp(filterText.toLowerCase());
        const filterTerm = name?.toString() || "";
        return regex.test(filterTerm.toLowerCase());
    });

    return (
        <div className="cui-data-grid-filter-input" data-testid={testId}>
            <Accordion
                label={title}
                defaultOpen={defaultOpen}
                testId={`${testId}-${TestIds.FilterDrawer.FilterListAccordion}`}
            >
                <div className="hermes-search-field">
                    {showSearch ? (
                        <SearchBox
                            className="text-filter-input"
                            isLabelHidden
                            label=""
                            onChange={setFilterText}
                            placeholder={placeholder}
                            value={filterText}
                            testId={TestIds.FilterDrawer.FilterListSearchBox}
                        />
                    ) : null}
                </div>
                <div className="option-list">
                    {filteredOptions.map((row) => {
                        const optionLabel = row.name as string;
                        return (
                            <span key={optionLabel}>
                                {
                                    <Checkbox
                                        isChecked={row.checked}
                                        label={optionLabel}
                                        onChange={() => filterUpdated(row.id)}
                                        testId={`${testId}-${TestIds.FilterDrawer.FilterListCheckBox}-${optionLabel}`}
                                    />
                                }
                            </span>
                        );
                    })}
                    {showSearch && showAlert ? (
                        <AlertBanner className="info-alert-banner" />
                    ) : null}
                </div>
            </Accordion>
        </div>
    );
}

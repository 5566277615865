import { LoadingDisplay } from "@components/loading";
import { DataGrid, DataGridProps, Switch } from "@cpchem/covalence-ui";
import { RequestTagsReviewDisplayTestIds } from "@pages/tag-management-page/test-ids";
import { Log, Tag } from "@services/hermes/model";
import { useTagManagement } from "@stores/tag-management-context";
import React, { useState } from "react";
import { baseColumns } from "./renderer/base-columns";
import { RequestTagReviewHeaderRenderer } from "./renderer/header";
import { RequestTagReviewRow } from "./renderer/row/index";
import "./styles.scss";

interface RequestTagReviewDisplayProps {
    updateTagRow: (
        id: string,
        tagName: string | undefined,
        pullFrequency: string | undefined,
        comments: Log | null | undefined
    ) => void;
    tagActivityModalOpen: (tagId: number, tagName: string | undefined) => void;
    isTagUpdateLoading: boolean;
}

export function RequestTagReviewDisplay({
    updateTagRow,
    tagActivityModalOpen,
    isTagUpdateLoading
}: RequestTagReviewDisplayProps) {
    const { state, dispatch } = useTagManagement();
    const { tagRequest } = state;
    const [switchStatus, setSwitchStatus] = useState(
        state.removedTagsSwitchStatus
    );
    const isSwitchOn = switchStatus === "On";
    let allTagsToReview: Tag[] = [];
    let notRemovedTagsToReview: Tag[] = [];
    if (tagRequest.items) {
        allTagsToReview = tagRequest.items;
        notRemovedTagsToReview = tagRequest.items.filter(
            (tag) => tag.tagStatus?.toLowerCase() !== "removed"
        );
    }
    let showTagsToReview;
    if (isSwitchOn) {
        showTagsToReview = allTagsToReview;
    } else {
        showTagsToReview = notRemovedTagsToReview;
    }

    function handleEmptyResults() {
        return (
            <div className="tag-review-grid-no-results">
                Unknown error occurred, please contact EHFIL Dev Support Group.
            </div>
        );
    }

    function toggleSwitch(checked: boolean) {
        if (checked) {
            setSwitchStatus("On");
            setRemovedTagsSwitchState("On");
        } else {
            setSwitchStatus("Off");
            setRemovedTagsSwitchState("Off");
        }
    }

    function setRemovedTagsSwitchState(statusText: string) {
        dispatch({
            type: "SET_REMOVED_TAGS_SWITCH_STATUS",
            payload: {
                removedTagsSwitchStatus: statusText
            }
        });
    }

    const gridProps: DataGridProps = {
        data: showTagsToReview,
        columns: baseColumns,
        useStickyHeaders: true,
        headerRenderer: () => <RequestTagReviewHeaderRenderer />,
        rowRenderer: (row) => (
            <RequestTagReviewRow
                key={row.id}
                row={row}
                updateTagRow={updateTagRow}
                tagActivityModalOpen={tagActivityModalOpen}
            />
        ),
        emptyResultsRenderer: () => handleEmptyResults()
    };

    return (
        <div className="request-tag-review-grid">
            <div className="request-tag-review-table-header">
                <p className="request-tag-review-table-heading">Tag Review</p>
                <div className="request-tag-review-table-switch">
                    <Switch
                        isChecked={isSwitchOn}
                        label="Show Removed Tags"
                        onChange={toggleSwitch}
                        testId={RequestTagsReviewDisplayTestIds.StatusToggle}
                    />
                    <p
                        className="request-tag-review-switch-status"
                        data-testid={
                            RequestTagsReviewDisplayTestIds.StatusToggleText
                        }
                    >
                        {switchStatus}
                    </p>
                </div>
            </div>
            <LoadingDisplay
                className="request-tag-review-loading-display"
                showLoading={isTagUpdateLoading}
            />
            <DataGrid
                key={showTagsToReview.length}
                {...gridProps}
                testId={RequestTagsReviewDisplayTestIds.DataGrid}
            />
        </div>
    );
}

import { AlertBannerProvider } from "@cpchem/covalence-ui";
import { NewTagsRequestTestIds } from "@pages/tag-management-page/test-ids";
import { TagManagementProvider } from "@stores/tag-management-context";
import React from "react";
import { CreateNewRequestView } from "./create-new-request-view";
import "./styles.scss";
export function CreateNewTagsRequestView(): JSX.Element {
    return (
        <div
            className="create-new-tags-request-view"
            data-testid={NewTagsRequestTestIds.CreateNewTagsRequestView}
        >
            <AlertBannerProvider>
                <TagManagementProvider>
                    <CreateNewRequestView showInfoMessage />
                </TagManagementProvider>
            </AlertBannerProvider>
        </div>
    );
}

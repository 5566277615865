import { FilterListItem } from "@models";
import React from "react";
import { FilterBadge } from "./filter-badge";

export interface FilterBadgesProps {
    className?: string;
    onDismiss: (id: string, title: string) => void;
    filters: FilterListItem[];
    title: string;
    testId?: string;
}

export function FilterBadges({
    className,
    onDismiss,
    filters,
    title,
    testId
}: FilterBadgesProps): JSX.Element {
    const cnParts = ["filter-badge"];

    if (className) {
        cnParts.push(className);
    }
    const cn = cnParts.join(" ");

    const selectedFilters = filters
        .filter((x) => x.checked === true && x.id !== "All")
        .map((appliedFilter) => {
            return (
                <FilterBadge
                    key={appliedFilter.id}
                    className={cn}
                    testId={testId}
                    title={title}
                    appliedFilter={appliedFilter}
                    onDismiss={onDismiss}
                />
            );
        });

    return <>{selectedFilters}</>;
}
